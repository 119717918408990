import { AxiosResponse } from "axios";

import { pageSize } from "../../Components/Common/styles/table";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import {
  setParticipantsLoader,
  setParticipantsCount,
  setParticipantsDetails,
  setParticipantsPage,
} from "../reducers/participantsSlice";
import { AppThunk } from "../store";

export const fetchParticipantsList =
  (
    page: number,
    type: string,
    filterId: string,
    searchText: string,
    userRole: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setParticipantsLoader(true));
      let url = "";
      if (userRole === "clinician") {
        url = `/participants/clinic-managed?page=${
          page + 1
        }&size=${pageSize}&status=${type}`;
      } else {
        url = `/participants?page=${page + 1}&size=${pageSize}&status=${type}`;
      }
      if (searchText) {
        url += `&search=${searchText}`;
      }
      const res: AxiosResponse = await http.get(url);
      const data = res.data?.data;
      if (
        type !== "unregistered" &&
        data?.users?.length === 0 &&
        data?.count > 0 &&
        page > 0
      ) {
        dispatch(
          setParticipantsPage({
            page: 0,
            pageSize,
          })
        );
        return;
      }
      if (
        type === "unregistered" &&
        data?.rows?.length === 0 &&
        data?.count > 0 &&
        page > 0
      ) {
        dispatch(
          setParticipantsPage({
            page: 0,
            pageSize,
          })
        );
        return;
      }

      const newList = (data?.users || data?.rows).map((user: any) => {
        return {
          id: user?.id,
          name: user?.firstName
            ? user?.lastName
              ? `${user?.firstName} ${user?.lastName}`
              : user?.firstName
            : "-",
          email: user?.email || "-",
          careGiver: user?.userCaregiver?.careGiver?.firstName
            ? user?.userCaregiver?.careGiver?.lastName
              ? `${user?.userCaregiver?.careGiver?.firstName} ${user?.userCaregiver?.careGiver?.lastName}`
              : user?.userCaregiver?.careGiver?.firstName
            : "-",
          status: user?.status,
        };
      });

      dispatch(setParticipantsDetails(newList));
      dispatch(setParticipantsCount(data?.count));
      dispatch(setParticipantsLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setParticipantsLoader(false));
    }
  };
