import { useEffect, useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  TextFieldProps,
} from "@mui/material";

import * as yup from "yup";
import { FieldArray, Formik } from "formik";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "./../../../Common/styles/modal";
import { setToggle } from "../../../../Redux/reducers/healthSlice";
import { InputWrapper, LabelStyle } from "./../../../Common/styles/form";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { useAppDispatch } from "../../../../Redux/hooks";
import http from "../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";

let schema = yup.object().shape({
  addressLine1: yup.string().optional(),
  // addressLine2: yup.string().optional(),
  addressCity: yup.string().optional(),
  addressState: yup.string().optional(),
  addressPostcode: yup.string().optional(),
  addressCountryISOCode: yup.string().optional(),
  orderNotes: yup.string().optional(),
  clinicOrderNotes: yup.string().optional(),
  deepBrainStimulator: yup
    .boolean()
    .required("Deep Brain Stimulator Notes are Required"),
  infusionTherapy: yup.string().optional(),
  medDetails: yup.array(),
  // .min(1, "Medication details are Required"),
  reminderDemo: yup.boolean().required("Reminder Demo is Required"),
  orderReminderTimes: yup.array(),
  // .min(1, "Order Reminder Time cannot be empty")
  // .of(yup.string().required("Order Reminder Time cannot be empty")),
  shipDate: yup.string().optional(),
  expirationDate: yup.string().optional(),
  pkgDueDate: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("PKG Due Date is Required"),
});

type Props = {
  showModal: boolean;
  closeModal: () => void;
  //   refreshPage: () => void;
  clinicId: string;
  data: any;
  address: any;
};

const AddOrderModal = ({
  showModal,
  closeModal,
  clinicId,
  data,
  address,
}: Props) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const { id } = useParams();
  const [searchLoader, setSearchLoader] = useState(false);
  const [loading, setLoading] = useState(true);

  const [medications, setMedications] = useState<any>([]);
  // const { role } = useAppSelector((state) => state.user);
  // const { clinicId } = useAppSelector((state) => state.health.userData);
  const [medDetails, setMedDetails] = useState<any[]>([]);
  const [orderReminderTimes, setOrderReminderTimes] = useState<any[]>([]);

  const dispatch = useAppDispatch();
  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          if (value) {
            setSearchLoader(true);
            let url = `/medication-management?search=${value}&noPagination=true`;
            const res: AxiosResponse = await http.get(url);
            const meds = res?.data?.data?.medications?.map((med: any) => {
              return {
                name: med?.propreiteryName,
                id: med?.id,
                // strength: med?.strength,
              };
            });
            setMedications(meds);
            setSearchLoader(false);
          }
        } catch (err) {
          errorToastMessage(err as Error);
          setSearchLoader(false);
        }
      }, 500),
    []
  );
  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      let url: string;
      let res: AxiosResponse;
      const body = {
        ...values,
      };
      body.orderReminderTimes = body.orderReminderTimes.filter(
        (order: string) => order
      );
      body.expirationDate = values.expirationDate
        ? DateTime.fromFormat(values.expirationDate, "dd/MM/yyyy").toFormat(
            "yyyy-MM-dd"
          )
        : undefined;
      body.pkgDueDate = DateTime.fromFormat(
        values.pkgDueDate,
        "dd/MM/yyyy"
      ).toFormat("yyyy-MM-dd");
      body.shipDate = values.shipDate
        ? DateTime.fromFormat(values.shipDate, "dd/MM/yyyy").toFormat(
            "yyyy-MM-dd"
          )
        : undefined;
      if (data?.orderId) {
        url = `/clinic/${clinicId}/orders/${data?.orderId}`;
        res = await http.put(url, body);
      } else {
        body.userId = id;
        url = `/clinic/${clinicId}/orders`;
        res = await http.post(url, body);
      }
      // role === "clinician"
      //   ? `/participants/clinic`
      //   : `/participants/${id}/clinic`;
      toastMessage("success", res.data.message);
      closeModal();
      dispatch(setToggle());
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };
  useEffect(() => {
    const formatData = (data: any) => {
      let formattedMedDetails: any[] = [];
      let formattedOrderReminderTimes: Set<string> = new Set();

      data.forEach((item: any) => {
        if (item?.medicationId) {
          const medicationName = item.name;
          if (!formattedMedDetails.includes(medicationName)) {
            formattedMedDetails.push(medicationName);
          }
          item.reminder.forEach((reminder: any) => {
            const dayTimings = reminder.dayTimings;
            dayTimings.forEach((time: string) => {
              formattedOrderReminderTimes.add(time.slice(0, 5));
            });
          });
        }
      });

      return {
        formattedMedDetails,
        formattedOrderReminderTimes: Array.from(formattedOrderReminderTimes),
      };
    };
    const fetchDetails = async () => {
      try {
        const res: AxiosResponse = await http.get(
          `/medication/list?userId=${id}`
        );
        const response = res?.data?.data;
        const data = formatData(response);
        setMedDetails(data?.formattedMedDetails);
        setOrderReminderTimes(data?.formattedOrderReminderTimes);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [id]);

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={data?.orderId ? "Update Order" : "Add Order"}
          onCloseClick={closeModal}
        />
        {!loading ? (
          <Formik
            initialValues={{
              // orderId: initialData?.orderId || "",
              addressLine1: data?.orderId
                ? data.addressLine1 || ""
                : address?.addressLine1 || "",
              addressCity: data?.orderId
                ? data.addressCity || ""
                : address?.city || "",
              addressState: data?.orderId
                ? data.addressState || ""
                : address?.state || "",
              addressPostcode: data?.orderId
                ? data.addressPostcode || ""
                : address?.postCode || "",
              addressCountryISOCode: data?.orderId
                ? data.addressCountryISOCode || ""
                : address?.isoCode || "",
              orderNotes: data?.orderNotes || "",
              clinicOrderNotes: data?.clinicOrderNotes || "",
              deepBrainStimulator: data?.deepBrainStimulator || false,
              infusionTherapy: data?.infusionTherapy || "",
              medDetails: medDetails?.length > 0 ? medDetails : [],
              reminderDemo: data?.reminderDemo || false,
              orderReminderTimes:
                orderReminderTimes?.length > 0 ? orderReminderTimes : [""],
              shipDate: data?.shipDate
                ? DateTime.fromFormat(data.shipDate, "yyyy-MM-dd").toFormat(
                    "dd/MM/yyyy"
                  )
                : "",
              expirationDate: data?.expirationDate
                ? DateTime.fromFormat(
                    data?.expirationDate,
                    "yyyy-MM-dd"
                  ).toFormat("dd/MM/yyyy")
                : "",
              pkgDueDate: data?.pkgDueDate
                ? DateTime.fromFormat(data?.pkgDueDate, "yyyy-MM-dd").toFormat(
                    "dd/MM/yyyy"
                  )
                : "",
            }}
            validationSchema={schema}
            onSubmit={(values) => {
              submitHandler(values);
            }}
          >
            {({
              handleSubmit,
              getFieldProps,
              errors,
              touched,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="order-id">
                    Order ID
                  </FormLabel>
                  <TextField
                    id="order-id"
                    {...getFieldProps("orderId")}
                    error={touched?.orderId && errors?.orderId ? true : false}
                    helperText={
                      touched?.orderId && errors?.orderId
                        ? (errors?.orderId as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="record-number">
                    Record Number
                  </FormLabel>
                  <TextField
                    id="record-number"
                    {...getFieldProps("recordNumber")}
                    error={
                      touched?.recordNumber && errors?.recordNumber
                        ? true
                        : false
                    }
                    helperText={
                      touched?.recordNumber && errors?.recordNumber
                        ? (errors?.recordNumber as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box> */}
                <Box sx={{ display: "flex", gap: 2 }}>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="address-1">
                      Address
                    </FormLabel>
                    <TextField
                      id="address-1"
                      {...getFieldProps("addressLine1")}
                      error={
                        touched?.addressLine1 && errors?.addressLine1
                          ? true
                          : false
                      }
                      helperText={
                        touched?.addressLine1 && errors?.addressLine1
                          ? (errors?.addressLine1 as string)
                          : " "
                      }
                    />
                  </FormControl>
                </Box>
                {/* <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="address-2">
                    Address Line 2
                  </FormLabel>
                  <TextField
                    id="address-2"
                    {...getFieldProps("addressLine2")}
                    error={
                      touched?.addressLine2 && errors?.addressLine2
                        ? true
                        : false
                    }
                    helperText={
                      touched?.addressLine2 && errors?.addressLine2
                        ? (errors?.addressLine2 as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box> */}
                <Box sx={{ display: "flex", gap: 2 }}>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="address-city">
                      Address City
                    </FormLabel>
                    <TextField
                      id="address-city"
                      {...getFieldProps("addressCity")}
                      error={
                        touched?.addressCity && errors?.addressCity
                          ? true
                          : false
                      }
                      helperText={
                        touched?.addressCity && errors?.addressCity
                          ? (errors?.addressCity as string)
                          : " "
                      }
                    />
                  </FormControl>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="address-state">
                      Address State
                    </FormLabel>
                    <TextField
                      id="address-state"
                      {...getFieldProps("addressState")}
                      error={
                        touched?.addressState && errors?.addressState
                          ? true
                          : false
                      }
                      helperText={
                        touched?.addressState && errors?.addressState
                          ? (errors?.addressState as string)
                          : " "
                      }
                    />
                  </FormControl>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="address-postalCode">
                      Address Zip Code
                    </FormLabel>
                    <TextField
                      id="address-postalcode"
                      {...getFieldProps("addressPostcode")}
                      error={
                        touched?.addressPostcode && errors?.addressPostcode
                          ? true
                          : false
                      }
                      helperText={
                        touched?.addressPostcode && errors?.addressPostcode
                          ? (errors?.addressPostcode as string)
                          : " "
                      }
                    />
                  </FormControl>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="address-countryIsoCode">
                      Country ISO Code
                    </FormLabel>
                    <TextField
                      id="address-countryIsoCode"
                      {...getFieldProps("addressCountryISOCode")}
                      error={
                        touched?.addressCountryISOCode &&
                        errors?.addressCountryISOCode
                          ? true
                          : false
                      }
                      helperText={
                        touched?.addressCountryISOCode &&
                        errors?.addressCountryISOCode
                          ? (errors?.addressCountryISOCode as string)
                          : " "
                      }
                    />
                  </FormControl>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="order-notes">
                      Order Notes
                    </FormLabel>
                    <TextField
                      id="order-notes"
                      {...getFieldProps("orderNotes")}
                      error={
                        touched?.orderNotes && errors?.orderNotes ? true : false
                      }
                      helperText={
                        touched?.orderNotes && errors?.orderNotes
                          ? (errors?.orderNotes as string)
                          : " "
                      }
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="clinic-order-notes">
                      Clinic Order Notes
                    </FormLabel>
                    <TextField
                      id="clinic-order-notes"
                      {...getFieldProps("clinicOrderNotes")}
                      error={
                        touched?.clinicOrderNotes && errors?.clinicOrderNotes
                          ? true
                          : false
                      }
                      helperText={
                        touched?.clinicOrderNotes && errors?.clinicOrderNotes
                          ? (errors?.clinicOrderNotes as string)
                          : " "
                      }
                    />
                  </FormControl>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="deep-brain-simulator">
                      Deep Brain Stimulator
                    </FormLabel>
                    <Select
                      fullWidth
                      id="deep-brain-simulator"
                      value={values.deepBrainStimulator}
                      onChange={(e) => {
                        setFieldValue("deepBrainStimulator", e.target.value);
                      }}
                    >
                      {[
                        { value: true, label: "Yes" },
                        { value: false, label: "No" },
                      ].map((item: any, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched?.deepBrainStimulator &&
                      errors?.deepBrainStimulator
                        ? (errors?.deepBrainStimulator as string)
                        : " "}
                    </FormHelperText>
                  </FormControl>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="infusion-therapy">
                      Infusion Therapy
                    </FormLabel>
                    <TextField
                      id="infusion-therapy"
                      {...getFieldProps("infusionTherapy")}
                      error={
                        touched?.infusionTherapy && errors?.infusionTherapy
                          ? true
                          : false
                      }
                      helperText={
                        touched?.infusionTherapy && errors?.infusionTherapy
                          ? (errors?.infusionTherapy as string)
                          : " "
                      }
                    />
                  </FormControl>
                </Box>

                <Box>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="med-details">
                      Medication Details
                    </FormLabel>
                    <Autocomplete
                      readOnly
                      filterOptions={(x) => x}
                      id="med-details"
                      onInputChange={(_1: any, value: any, reason: string) => {
                        if (reason === "input") {
                          handleSearch(value);
                        }
                        setFieldValue("medDetails", {
                          name: value,
                          id: null,
                        });
                      }}
                      onChange={(_1: any, value: any) => {
                        const newMedDetails = Array.isArray(value)
                          ? value
                          : [value];
                        setFieldValue(
                          "medDetails",
                          newMedDetails.map((med) => {
                            return med?.name ? med?.name : med;
                          })
                        );
                      }}
                      options={medications}
                      value={values?.medDetails}
                      multiple
                      getOptionLabel={(option) => option?.name || option}
                      isOptionEqualToValue={(option, value) => {
                        return option?.name === value;
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option?.id}>
                          {option?.name}
                        </li>
                      )}
                      loading={searchLoader}
                      loadingText={<CircularProgress size={20} />}
                      noOptionsText="No Results"
                      clearOnBlur={false}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <FormHelperText error>
                      {touched?.medDetails &&
                      errors?.medDetails &&
                      typeof errors?.medDetails === "string"
                        ? "*Medication is Required"
                        : " "}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="orderReminderTimes">
                      Reminder Times
                    </FormLabel>
                    <FieldArray name="orderReminderTimes">
                      {({ remove, push }) => (
                        <>
                          {values?.orderReminderTimes?.map(
                            (timeString: any, index: number) => (
                              <Box key={index}>
                                <TimePicker
                                  readOnly
                                  inputFormat="hh:mm a"
                                  value={
                                    timeString
                                      ? DateTime.fromFormat(timeString, "HH:mm")
                                      : null
                                  }
                                  onChange={(newValue: any) =>
                                    setFieldValue(
                                      `orderReminderTimes[${index}]`,
                                      newValue.toFormat("HH:mm")
                                    )
                                  }
                                  // minutesStep={30}
                                  renderInput={(
                                    params: JSX.IntrinsicAttributes &
                                      TextFieldProps
                                  ) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        mb: 2,
                                        gap: 1,
                                      }}
                                    >
                                      <TextField
                                        {...params}
                                        sx={{ width: "100%" }}
                                        inputProps={{
                                          ...params.inputProps,
                                          readOnly: true,
                                          id: "orderReminderTimes",
                                        }}
                                      />
                                    </Box>
                                  )}
                                />
                                {/* @ts-ignore */}
                                {touched?.orderReminderTimes?.[index] &&
                                  // @ts-ignore
                                  errors?.orderReminderTimes?.[index] && (
                                    <FormHelperText error sx={{ mb: 1 }}>
                                      {/* @ts-ignore */}
                                      {
                                        // @ts-ignore
                                        errors?.orderReminderTimes?.[
                                          index
                                        ] as string
                                      }
                                    </FormHelperText>
                                  )}
                              </Box>
                            )
                          )}
                        </>
                      )}
                    </FieldArray>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="reminder-demo">
                      Reminder Demo
                    </FormLabel>
                    <Select
                      id="reminder-demo"
                      value={values?.reminderDemo}
                      onChange={(e) => {
                        setFieldValue("reminderDemo", e.target.value);
                      }}
                    >
                      {[
                        { value: true, label: "Yes" },
                        { value: false, label: "No" },
                      ].map((item: any, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched?.reminderDemo && errors?.reminderDemo
                        ? (errors?.reminderDemo as string)
                        : " "}
                    </FormHelperText>
                  </FormControl>
                </Box>

                <Box sx={{ display: "flex", gap: 2 }}>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="ship-date">
                      Ship Date
                    </FormLabel>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={
                        values?.shipDate
                          ? DateTime.fromFormat(values?.shipDate, "dd/MM/yyyy")
                          : null
                      }
                      onChange={(newValue: any) => {
                        setFieldValue(
                          "shipDate",
                          newValue.toFormat("dd/MM/yyyy")
                        );
                      }}
                      renderInput={(
                        params: JSX.IntrinsicAttributes & TextFieldProps
                      ) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                            placeholder: "Select date",
                            id: "ship-date",
                          }}
                          error={
                            touched?.shipDate && errors?.shipDate ? true : false
                          }
                          helperText={
                            touched?.shipDate && errors?.shipDate
                              ? (errors?.shipDate as string)
                              : " "
                          }
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="expiration-date">
                      Expiration Date
                    </FormLabel>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={
                        values?.expirationDate
                          ? DateTime.fromFormat(
                              values?.expirationDate,
                              "dd/MM/yyyy"
                            )
                          : null
                      }
                      onChange={(newValue: any) => {
                        setFieldValue(
                          "expirationDate",
                          newValue.toFormat("dd/MM/yyyy")
                        );
                      }}
                      renderInput={(
                        params: JSX.IntrinsicAttributes & TextFieldProps
                      ) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                            placeholder: "Select date",
                            id: "expiration-date",
                          }}
                          error={
                            touched?.expirationDate && errors?.expirationDate
                              ? true
                              : false
                          }
                          helperText={
                            touched?.expirationDate && errors?.expirationDate
                              ? (errors?.expirationDate as string)
                              : " "
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <FormControl sx={{ ...InputWrapper, width: "50%" }}>
                    <FormLabel sx={LabelStyle} htmlFor="pkg-due-date">
                      PKG Due Date <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={
                        values?.pkgDueDate
                          ? DateTime.fromFormat(
                              values?.pkgDueDate,
                              "dd/MM/yyyy"
                            )
                          : null
                      }
                      onChange={(newValue: any) => {
                        setFieldValue(
                          "pkgDueDate",
                          newValue.toFormat("dd/MM/yyyy")
                        );
                      }}
                      renderInput={(
                        params: JSX.IntrinsicAttributes & TextFieldProps
                      ) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                            placeholder: "Select date",
                            id: "pkg-due-date",
                          }}
                          error={
                            touched?.pkgDueDate && errors?.pkgDueDate
                              ? true
                              : false
                          }
                          helperText={
                            touched?.pkgDueDate && errors?.pkgDueDate
                              ? (errors?.pkgDueDate as string)
                              : " "
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Box>
                {/* <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="address-street">
                    Address Street <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="address-street"
                    {...getFieldProps("street")}
                    error={touched?.street && errors?.street ? true : false}
                    helperText={
                      touched?.street && errors?.street
                        ? (errors?.street as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box> */}

                <Box sx={ModalActionButtonStyles}>
                  {!submitLoader ? (
                    <>
                      <Button type="submit" variant="contained">
                        Save
                      </Button>
                      <Button onClick={closeModal} variant="outlined">
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <CircularProgress size={25} />
                  )}
                </Box>
              </form>
            )}
          </Formik>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AddOrderModal;
