import React, { useEffect } from "react";
import HealthHeader from "./HealthHeader";
import HealthTabs from "./HealthTabs";
import HealthDateSelector from "./HealthDateSelector";
// import HealthGraph from "./HealthGraph";
// import HealthMetrics from "./HealthMetrics";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setHealthData,
  setHealthLoading,
} from "../../../Redux/reducers/healthSlice";
import { DateTime, Settings } from "luxon";
import HealthBlockSelector from "./HealthBlockSelector";
import { Box } from "@mui/material";
import http from "../../../utils/http";
import { useNavigate, useParams } from "react-router-dom";
import { errorToastMessage } from "../../../utils/toast";

const Health: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { loading, type } = useAppSelector((state) => state.health);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTimezoneDetails = async () => {
      try {
        const res = await http.get(`/user-preference?userId=${id}`);
        Settings.defaultZone = res.data.data?.timezone || "system";
        const isValid = DateTime.local().isValid;
        if (!isValid) {
          Settings.defaultZone = "system";
        }
        dispatch(
          setHealthData({
            start: DateTime.now().plus({ days: -7 }).toFormat("dd-LL-yyyy"),
            end: DateTime.now().toFormat("dd-LL-yyyy"),
          })
        );
      } catch (err) {
        errorToastMessage(err as Error);
        navigate("/app/participants");
      }
    };
    fetchTimezoneDetails();
    return () => {
      dispatch(setHealthLoading(true));
      Settings.defaultZone = "system";
    };
  }, [dispatch, id]);

  return (
    <>
      <HealthHeader />
      <HealthTabs />
      {loading ? null : (
        <>
          {type === "patient_overview" ? (
            <Box sx={{ width: "100%", mt: 2 }}></Box>
          ) : (
            <HealthDateSelector />
          )}
          <HealthBlockSelector />
        </>
      )}
    </>
  );
};

export default Health;
