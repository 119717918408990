import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../Redux/hooks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { errorToastMessage } from "../../../../../utils/toast";
import { NoDataContainer } from "../../../../Common/styles/table";
import { DateTime } from "luxon";
import {
  CheckCircleRounded,
  ExpandMore,
  WarningRounded,
} from "@mui/icons-material";
import ExercisesItem from "./ExercisesItem";
import {
  JournalAccordion,
  JournalAccordionHeader,
  JournalAccordionHeaderContainer,
  loggedContainer,
  notloggedContainer,
} from "../style";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";

export interface exerciseEntry {
  id: string;
  name: string;
  imageUrl: string | null;
  time: string;
  deleted: boolean;
}
interface ExercisesMetrics {
  dateLabel: string;
  dayLabel: string;
  logged: boolean;
  entries: Array<exerciseEntry>;
}

const Exercises = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ExercisesMetrics[]>([]);
  const [error, setError] = useState("");
  const { startDate, endDate } = useAppSelector((state) => state.health);

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (startDate && endDate) {
          setLoading(true);
          const start = DateTime.fromFormat(startDate, "dd-LL-yyyy").startOf(
            "day"
          );
          let end = DateTime.fromFormat(endDate, "dd-LL-yyyy").endOf("day");
          const res: AxiosResponse = await http.get(
            `/activity/all?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}&activity=exercise`
          );
          const data = res.data.data;
          const n = end.diff(start, "days").get("days");
          const newData = [];
          for (let i = 0; i < n; i++) {
            const key = end.toFormat("yyyy-LL-dd");
            const entries = (data?.[key] || []).map((entry: any) => {
              const exerciseData = entry?.exercise;
              return {
                id: entry?.id,
                name: exerciseData?.name || "Deleted Exercise",
                imageUrl: exerciseData?.thumbnailUrl,
                time: entry.time,
                deleted: !!exerciseData?.deletedAt,
              };
            });
            newData.push({
              dayLabel: end.toFormat("cccc"),
              dateLabel: end.toFormat("dd LLLL yyyy"),
              logged: entries.length > 0,
              entries: entries,
            });
            end = end.plus({
              day: -1,
            });
          }
          setData(newData);
          setError("");
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setError("Something went Wrong");
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [startDate, endDate, setLoading, setError, setData, id]);

  return loading ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={25} />
    </Box>
  ) : error ? (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        {error}
      </Typography>
    </NoDataContainer>
  ) : data.length > 0 ? (
    <>
      {data.map((item) => {
        return (
          <Accordion
            key={item.dateLabel}
            TransitionProps={{ unmountOnExit: true }}
            expanded={expanded === `panel${item.dateLabel}`}
            onChange={handleChange(`panel${item.dateLabel}`)}
            sx={JournalAccordion}
          >
            <AccordionSummary
              expandIcon={
                <IconButton>
                  <ExpandMore sx={{ fontSize: 24 }} />
                </IconButton>
              }
              sx={{
                ".Mui-expanded": {
                  m: "12px 0px !important",
                },
              }}
            >
              <Box sx={JournalAccordionHeaderContainer}>
                <Box sx={JournalAccordionHeader}>
                  <Box>
                    <Typography
                      fontWeight={600}
                      fontSize={"22px"}
                      color={"#355962"}
                      mb={0.5}
                    >
                      {item?.dateLabel}
                    </Typography>
                    <Typography
                      fontWeight={500}
                      fontSize={"16px"}
                      color={"#355962"}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item?.dayLabel}
                    </Typography>
                  </Box>
                </Box>
                {item.logged ? (
                  <Box sx={loggedContainer}>
                    <CheckCircleRounded sx={{ mr: 1 }} />
                    Exercises Logged
                  </Box>
                ) : (
                  <Box sx={notloggedContainer}>
                    <WarningRounded sx={{ mr: 1 }} />
                    Exercises Not Logged
                  </Box>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                p: 2,
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill , minmax(272px , 1fr))",
                gap: 2,
                mb: 2,
              }}
            >
              {item.entries.length > 0 ? (
                item.entries.map((logEntry) => {
                  return (
                    <ExercisesItem key={logEntry.id} exercise={logEntry} />
                  );
                })
              ) : (
                <NoDataContainer>
                  <Typography variant="body1" color="gray">
                    No Data
                  </Typography>
                </NoDataContainer>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  ) : (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        No Data
      </Typography>
    </NoDataContainer>
  );
};

export default Exercises;
