import React, { useState } from "react";
import { AxiosResponse } from "axios";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  TextFieldProps,
} from "@mui/material";

import * as yup from "yup";
import { Formik } from "formik";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../Common/styles/modal";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import http from "../../../utils/http";
import { useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "libphonenumber-js";
import { timezones } from "../../../utils/timezone";

let schema = yup.object().shape({
  firstName: yup.string().required("First Name is Required"),
  lastName: yup.string().required("Last Name is Required"),
  email: yup
    .string()
    .email("Please enter a valid Email ID.")
    .required("Email ID is Required"),
  // clinicId: yup.string().required("Clinic ID is Required"),
  // clinicianId: yup.string().required("Clinician ID is Required"),
  phoneNumber: yup
    .string()
    .required("Phone Number is Required")
    .test("phone-number-test", "Enter a valid phone number", (value) => {
      let valid = false;
      try {
        if (value) {
          valid = isValidPhoneNumber("+" + value);
        }
      } catch (err) {
      } finally {
        return valid;
      }
    }),
  timezone: yup.string().required("Time Zone is Required"),
  addressLine1: yup.string().required("First Address Line is Required"),
  addressLine2: yup.string().required("Second Address Line is Required"),
  addressCity: yup.string().required("Address City is Required"),
  addressState: yup.string().required("Address State is Required"),
  addressPostcode: yup.string().required("Address Zip Code is Required"),
  addressCountryISOCode: yup
    .string()
    .required("Address Coutry ISO Code is Required"),
  dob: yup.string().required("Date of Birth is Required"),
});

// type Props = {
//   showModal: boolean;
//   closeModal: () => void;
//   refreshPage: () => void;
//   data: any;
// };

const AddPatientModal: React.FC<any> = ({
  showModal,
  closeModal,
  refreshPage,
  data,
}) => {
  const [submitLoader, setSubmitLoader] = useState(false);

  const { id } = useParams();

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      // let url =
      //   role === "clinician"
      //     ? `/participants/clinic`
      //     : `/participants/${id}/clinic`;
      let url: string;
      let res: AxiosResponse;
      const body = {
        ...values,
        phoneNumber: "+" + values.phoneNumber,
      };
      body.dob = DateTime.fromFormat(values.dob, "dd/MM/yyyy").toFormat(
        "yyyy-MM-dd"
      );
      if (id) {
        url = `/participants/${id}/edit-patient`;
        res = await http.patch(url, body);
      } else {
        url = `/participants/clinic`;
        res = await http.post(url, body);
      }
      toastMessage("success", res.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={id ? "Edit Patient" : "Add New Patient"}
          onCloseClick={closeModal}
        />
        <Formik
          initialValues={{
            firstName: data?.firstName || "",
            lastName: data?.lastName || "",
            // middleName: "",
            email: data?.email || "",
            phoneNumber: data?.phoneNumber ? data?.phoneNumber?.slice(1) : "",
            timezone: data?.timezone || "",
            // recordNumber: "",
            dob: data?.dob || "",
            // clinicId: "",
            // clinicianId: "",
            addressLine1: data?.addressLine1 || "",
            addressLine2: data?.addressLine2 || "",
            addressCity: data?.addressCity || "",
            addressState: data?.addressState || "",
            addressPostcode: data?.addressPostcode || "",
            addressCountryISOCode: data?.addressCountryISOCode || "",
            mostAffectedSide: data?.mostAffectedSide || "1",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            handleSubmit,
            getFieldProps,
            setFieldTouched,
            errors,
            touched,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="first-name">
                    First Name <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="first-name"
                    {...getFieldProps("firstName")}
                    error={
                      touched?.firstName && errors?.firstName ? true : false
                    }
                    helperText={
                      touched?.firstName && errors?.firstName
                        ? (errors?.firstName as string)
                        : " "
                    }
                  />
                </FormControl>
                {/* <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="middle-name">
                    Middle Name
                  </FormLabel>
                  <TextField
                    id="middle-name"
                    {...getFieldProps("middleName")}
                    error={
                      touched?.middleName && errors?.middleName ? true : false
                    }
                    helperText={
                      touched?.middleName && errors?.middleName
                        ? (errors?.middleName as string)
                        : " "
                    }
                  />
                </FormControl> */}
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="last-name">
                    Last Name <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="last-name"
                    {...getFieldProps("lastName")}
                    error={touched?.lastName && errors?.lastName ? true : false}
                    helperText={
                      touched?.lastName && errors?.lastName
                        ? (errors?.lastName as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="email">
                    Email <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="email"
                    inputProps={{ readOnly: id ? true : false }}
                    {...getFieldProps("email")}
                    error={touched?.email && errors?.email ? true : false}
                    helperText={
                      touched?.email && errors?.email
                        ? (errors?.email as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl
                  sx={{
                    ...InputWrapper,
                    justifyContent: "unset",
                  }}
                >
                  <FormLabel sx={LabelStyle} htmlFor="contact-number">
                    Contact Number <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <PhoneInput
                    inputProps={{
                      id: "contact-number",
                    }}
                    value={values.phoneNumber}
                    onChange={(value) => setFieldValue("phoneNumber", value)}
                    onBlur={() => {
                      setFieldTouched("phoneNumber", true);
                    }}
                    country={"us"}
                    inputStyle={{
                      width: "100%",
                      height: "52px",
                      borderRadius: "6px",
                      fontSize: "16px",
                    }}
                    isValid={
                      touched?.phoneNumber && errors?.phoneNumber ? false : true
                    }
                  />
                  {touched?.phoneNumber && errors?.phoneNumber && (
                    <FormHelperText error>
                      {errors?.phoneNumber as string}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="time-zone">
                    Time Zone <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Autocomplete
                    disablePortal
                    id="time-zone"
                    options={timezones}
                    value={values.timezone || null}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Timezone" />
                    )}
                    onChange={(_1: any, value) => {
                      console.log(value);
                      setFieldValue("timezone", value);
                    }}
                    onBlur={() => setFieldTouched("timezone", true)}
                  />
                  <FormHelperText error>
                    {touched?.timezone && errors?.timezone
                      ? (errors?.timezone as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
                {/* <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="record-number">
                    Record Number
                  </FormLabel>
                  <TextField
                    id="record-number"
                    {...getFieldProps("recordNumber")}
                    error={
                      touched?.recordNumber && errors?.recordNumber
                        ? true
                        : false
                    }
                    helperText={
                      touched?.recordNumber && errors?.recordNumber
                        ? (errors?.recordNumber as string)
                        : " "
                    }
                  />
                </FormControl> */}
              </Box>
              <Box>
                <FormControl sx={{ ...InputWrapper, width: "49%" }}>
                  <FormLabel sx={LabelStyle} htmlFor="dob">
                    DOB <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={
                      values?.dob
                        ? DateTime.fromFormat(values?.dob, "dd/MM/yyyy")
                        : null
                    }
                    onChange={(newValue: any) => {
                      setFieldValue("dob", newValue.toFormat("dd/MM/yyyy"));
                    }}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps
                    ) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                          placeholder: "Select date",
                          id: "dob",
                        }}
                        error={touched?.dob && errors?.dob ? true : false}
                        helperText={
                          touched?.dob && errors?.dob
                            ? (errors?.dob as string)
                            : " "
                        }
                      />
                    )}
                  />
                </FormControl>
              </Box>
              {/* <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="clinic-id">
                    Clinic ID <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="clinic-id"
                    {...getFieldProps("clinicId")}
                    error={touched?.clinicId && errors?.clinicId ? true : false}
                    helperText={
                      touched?.clinicId && errors?.clinicId
                        ? (errors?.clinicId as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="clinician-id">
                    Clinician ID <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="clinician-id"
                    {...getFieldProps("clinicianId")}
                    error={
                      touched?.clinicianId && errors?.clinicianId ? true : false
                    }
                    helperText={
                      touched?.clinicianId && errors?.clinicianId
                        ? (errors?.clinicianId as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box> */}
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="address">
                    Address <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="address"
                    {...getFieldProps("addressLine1")}
                    error={
                      touched?.addressLine1 && errors?.addressLine1
                        ? true
                        : false
                    }
                    helperText={
                      touched?.addressLine1 && errors?.addressLine1
                        ? (errors?.addressLine1 as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="second-address">
                    Second Line Address <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="second-address"
                    {...getFieldProps("addressLine2")}
                    error={
                      touched?.addressLine2 && errors?.addressLine1
                        ? true
                        : false
                    }
                    helperText={
                      touched?.addressLine2 && errors?.addressLine2
                        ? (errors?.addressLine2 as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="city-name">
                    City <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="city-name"
                    {...getFieldProps("addressCity")}
                    error={
                      touched?.addressCity && errors?.addressCity ? true : false
                    }
                    helperText={
                      touched?.addressCity && errors?.addressCity
                        ? (errors?.addressCity as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="state-name">
                    State <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="state-name"
                    {...getFieldProps("addressState")}
                    error={
                      touched?.addressState && errors?.addressState
                        ? true
                        : false
                    }
                    helperText={
                      touched?.addressState && errors?.addressState
                        ? (errors?.addressState as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="post-code">
                    Zip Code <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="post-code"
                    {...getFieldProps("addressPostcode")}
                    error={
                      touched?.addressPostcode && errors?.addressPostcode
                        ? true
                        : false
                    }
                    helperText={
                      touched?.addressPostcode && errors?.addressPostcode
                        ? (errors?.addressPostcode as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="iso-code">
                    Country ISO Code <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="iso-code"
                    {...getFieldProps("addressCountryISOCode")}
                    error={
                      touched?.addressCountryISOCode &&
                      errors?.addressCountryISOCode
                        ? true
                        : false
                    }
                    helperText={
                      touched?.addressCountryISOCode &&
                      errors?.addressCountryISOCode
                        ? (errors?.addressCountryISOCode as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>

              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="affected-side">
                    Most Affected Side
                  </FormLabel>
                  <Select
                    id="affected-side"
                    sx={{ mb: 2, width: "50%" }}
                    value={values.mostAffectedSide}
                    onChange={(e) => {
                      setFieldValue("mostAffectedSide", e.target.value);
                    }}
                  >
                    {[
                      { value: "1", label: "Left" },
                      { value: "2", label: "Right" },
                      { value: "3", label: "Both" },
                    ].map((item: any, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched?.mostAffectedSide && errors?.mostAffectedSide
                      ? (errors?.mostAffectedSide as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box sx={ModalActionButtonStyles}>
                {!submitLoader ? (
                  <>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddPatientModal;
