export const Logo = () => (
  <svg
    width="112"
    height="66"
    viewBox="0 0 112 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M109.526 20.9844H92.4207C91.3288 20.9844 90.4453 21.8676 90.4453 22.9592C90.4453 24.0508 91.3288 24.934 92.4207 24.934L111.502 24.9288V22.9592C111.502 21.8676 110.618 20.9844 109.526 20.9844Z"
      fill="url(#paint0_linear_4407_6943)"
    />
    <path
      d="M107.548 37.6167C104.146 40.43 99.8784 41.9672 95.4398 41.9672C84.9564 41.9672 76.4273 33.4403 76.4273 22.9597C76.4273 12.4791 84.9564 3.9523 95.4398 3.9523C100.176 3.9523 104.707 5.69511 108.194 8.8617C109.001 9.59468 110.251 9.53404 110.984 8.72723C111.717 7.92042 111.657 6.67066 110.85 5.93768C106.635 2.1093 101.16 0 95.4398 0C82.778 0 72.4766 10.2987 72.4766 22.9571C72.4766 35.6155 82.778 45.9142 95.4398 45.9142C101.147 45.9142 106.619 43.8075 110.847 39.9791C111.261 39.6047 111.496 39.0721 111.496 38.5158V28.8763H107.545V37.6141L107.548 37.6167Z"
      fill="url(#paint1_linear_4407_6943)"
    />
    <path
      d="M18.2097 0.00195312H2.47536C1.38351 0.00195312 0.5 0.887859 0.5 1.97679C0.5 3.06571 1.38351 3.95162 2.47536 3.95162H18.2097C21.9863 3.95162 25.8474 4.87444 25.8474 11.7244C25.8474 19.6079 22.0206 20.4753 18.1015 20.6863C14.2906 20.8919 2.62833 20.6863 2.50965 20.6836C1.97163 20.6652 1.4679 20.8787 1.09076 21.2479C0.713624 21.6196 0.5 22.1259 0.5 22.6558V24.6913C0.5 24.6913 16.6668 24.715 18.3152 24.6254C21.1556 24.4725 29.7981 24.0031 29.7981 11.7191C29.7981 6.37731 27.7885 0.00195312 18.2097 0.00195312Z"
      fill="url(#paint2_linear_4407_6943)"
    />
    <path
      d="M0.521099 28.6172C0.521099 28.6172 0.5 28.6251 0.5 28.6383V43.8384C0.5 44.8799 1.26219 45.8185 2.29866 45.9082C3.46964 46.011 4.45072 45.0908 4.45072 43.9412V28.6383C4.45072 28.6383 4.44281 28.6172 4.42962 28.6172H0.521099Z"
      fill="url(#paint3_linear_4407_6943)"
    />
    <path
      d="M51.9944 25.596C51.9654 25.567 51.9338 25.5485 51.9021 25.5195L49.125 28.2959C49.1514 28.3249 49.1725 28.3592 49.1988 28.3882L66.1516 45.3364C66.5367 45.7214 67.043 45.9138 67.5494 45.9138C68.0558 45.9138 68.5595 45.7214 68.9472 45.3364C69.7199 44.5665 69.7199 43.3141 68.9472 42.5442L51.9944 25.596Z"
      fill="url(#paint4_linear_4407_6943)"
    />
    <path
      d="M68.4674 3.3742C69.2402 2.6043 69.2402 1.35191 68.4674 0.582011C67.6973 -0.190521 66.4446 -0.190521 65.6745 0.582011L41.564 24.686V1.97679C41.564 0.887859 40.6805 0.00195312 39.5886 0.00195312C38.4968 0.00195312 37.6133 0.885223 37.6133 1.97679V43.9413C37.6133 45.0329 38.4968 45.9161 39.5886 45.9161C40.6805 45.9161 41.564 45.0329 41.564 43.9413V30.2704L68.4674 3.3742Z"
      fill="url(#paint5_linear_4407_6943)"
    />
    <path
      d="M37.9883 66.0027V56.002H39.1223V60.442H44.9403V56.002H46.0743V66.0027H44.9403V61.5468H39.1223V66.0027H37.9883Z"
      fill="#22314D"
    />
    <path
      d="M52.6602 66.0027V56.002H60.0183V57.0909H53.7942V60.4447H59.3537V61.5468H53.7942V64.9164H60.0183V66.0027H52.6602Z"
      fill="#22314D"
    />
    <path
      d="M64.8086 66.0027L69.0547 56.002H70.4762L74.7223 66.0027H73.467L72.2432 63.1024H67.2877L66.064 66.0027H64.8086ZM67.7255 62.0609H71.8054L69.7668 57.1805L67.7281 62.0609H67.7255Z"
      fill="#22314D"
    />
    <path
      d="M80.1289 66.0027V56.002H81.263V64.9164H86.717V66.0027H80.1289Z"
      fill="#22314D"
    />
    <path
      d="M93.1832 66.0027V57.0751H89.6914V56.002H97.8065V57.0751H94.3146V66.0027H93.1806H93.1832Z"
      fill="#22314D"
    />
    <path
      d="M103.383 66.0027V56.002H104.517V60.442H110.335V56.002H111.469V66.0027H110.335V61.5468H104.517V66.0027H103.383Z"
      fill="#22314D"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4407_6943"
        x1="90.6704"
        y1="23.4"
        x2="115.926"
        y2="22.8484"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22314D" />
        <stop offset="1" stopColor="#22314D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4407_6943"
        x1="72.8938"
        y1="28.081"
        x2="119.717"
        y2="27.918"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22314D" />
        <stop offset="1" stopColor="#22314D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4407_6943"
        x1="0.813252"
        y1="15.1036"
        x2="35.9697"
        y2="14.9328"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22314D" />
        <stop offset="1" stopColor="#22314D" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4407_6943"
        x1="0.542241"
        y1="39.1971"
        x2="5.28304"
        y2="39.1927"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22314D" />
        <stop offset="1" stopColor="#22314D" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4407_6943"
        x1="49.3431"
        y1="37.9926"
        x2="73.8245"
        y2="37.8923"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22314D" />
        <stop offset="1" stopColor="#22314D" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_4407_6943"
        x1="37.9494"
        y1="28.083"
        x2="75.669"
        y2="27.9772"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22314D" />
        <stop offset="1" stopColor="#22314D" />
      </linearGradient>
    </defs>
  </svg>
);
