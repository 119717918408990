import { Box } from "@mui/material";
import { ResponsiveBarCanvas } from "@nivo/bar";
import { renderGridLinesToCanvas } from "@nivo/axes";
import { DateTime } from "luxon";

// const getOrdinalIndicator = (day: number) => {
//   if (day >= 11 && day <= 13) {
//     return `${day} th`;
//   } else {
//     switch (day % 10) {
//       case 1:
//         return `${day} st`;
//       case 2:
//         return `${day} nd`;
//       case 3:
//         return `${day} rd`;
//       default:
//         return `${day} th`;
//     }
//   }
// };

const MonthlyInsight = ({ data }: any) => (
  <Box sx={{ height: "320px" }}>
    <ResponsiveBarCanvas
      data={data}
      keys={["value"]}
      indexBy="startDate"
      colors={["#75BEC7"]}
      margin={{ top: 30, right: 0, bottom: 30, left: 50 }}
      padding={0.6}
      groupMode="grouped"
      valueScale={{ type: "linear", min: 0, max: 100, clamp: true }}
      indexScale={{ type: "band" }}
      innerPadding={3}
      axisTop={null}
      axisRight={{ tickSize: 0 }}
      enableGridX={true}
      borderRadius={8}
      layers={[
        "axes",
        (ctx, { innerWidth, innerHeight, xScale, yScale }) => {
          ctx.lineWidth = 1;
          ctx.strokeStyle = "#E0E3EB";
          ctx.setLineDash([6, 6]);
          renderGridLinesToCanvas<string | number>(ctx, {
            width: innerWidth,
            height: innerHeight,
            scale: xScale,
            axis: "x",
          });
          renderGridLinesToCanvas<string | number>(ctx, {
            width: innerWidth,
            height: innerHeight,
            scale: yScale,
            axis: "y",
            values: [25, 50, 75, 100],
          });
        },
        "bars",
      ]}
      renderBar={(ctx, { bar, borderRadius }) => {
        ctx.fillStyle = bar.color;
        ctx.beginPath();
        if (bar.width < borderRadius) {
          borderRadius = bar.width / 2;
        }
        if (borderRadius > 0) {
          const radius = Math.min(borderRadius, bar.height);
          ctx.moveTo(bar.x + radius, bar.y);
          ctx.lineTo(bar.x + bar.width - radius, bar.y);
          ctx.quadraticCurveTo(
            bar.x + bar.width,
            bar.y,
            bar.x + bar.width,
            bar.y + radius
          );
          ctx.lineTo(bar.x + bar.width, bar.y + bar.height);
          ctx.lineTo(bar.x, bar.y + bar.height);
          ctx.lineTo(bar.x, bar.y + radius);
          ctx.quadraticCurveTo(bar.x, bar.y, bar.x + radius, bar.y);
          ctx.closePath();
        }
        ctx.fill();
      }}
      theme={{
        fontSize: 14,
        textColor: "#355962",
        axis: {
          ticks: {
            text: {
              fontSize: 14,
              fontWeight: 550,
            },
          },
          domain: {
            line: {
              stroke: "#E0E3EB",
            },
          },
        },
      }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0,
        tickValues:
          data.length > 31
            ? data
                .map((v: any, i: number) => {
                  const divider = data.length > 50 ? 3 : 2;
                  if (i % divider === 0) return v?.startDate;
                  else return null;
                })
                .filter((v: any) => v)
            : undefined,
        format: (d) => {
          const startDate = DateTime.fromFormat(d, "dd/MM/yyyy");
          const Ordinal = startDate.day;
          // const endDate = startDate.plus({ days: 6 });
          // const startOrdinal = getOrdinalIndicator(startDate.day);
          // const endOrdinal = getOrdinalIndicator(endDate.day);
          // return `${startOrdinal} - ${endOrdinal}`;
          return `${Ordinal}`;
        },
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 15,
        tickRotation: 0,
        tickValues: [25, 50, 75, 100],
        format: (value) => `${value}%`,
      }}
      enableLabel={false}
      tooltipLabel={(value: any) => {
        return value?.indexValue;
      }}
    />
  </Box>
);

export default MonthlyInsight;
