import { useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { Add, ArrowBack } from "@mui/icons-material";
import {
  StyledHeader,
  HeaderLeftContent,
  HeaderRightContent,
} from "../Common/styles/header";
import {
  MainTabPanel,
  StyledTab,
  StyledTabs,
  a11yProps,
} from "../Common/UI/TabPanel";
import { useNavigate, useSearchParams } from "react-router-dom";
import ClinicOverview from "./Tabs/ClinicOverview";
import Patients from "./Tabs/Patients";
import Clinicians from "./Tabs/Clinicians";
import ClinicsUrlSetter from "./ClinicsUrlSetter";
import ClinicsUrlLoader from "./ClinicsUrlLoader";
import { useAppSelector } from "../../Redux/hooks";
import {
  // canAddClinicPatients,
  canAddClinicians,
  canViewClinicPatients,
} from "../../utils/roles";

const ClinicDetails = () => {
  const [type, setType] = useState("clinicOverview");
  const [page, setPage] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>("clinicOverview");
  const [urlLoaded, setUrlLoaded] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [title] = useState(searchParams.get("title") || "Clinic Details");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const userRole = useAppSelector((state) => state.user.role);
  const [hasModifyAccess] = useState(canAddClinicians(userRole));
  const [hasPatientsAccess] = useState(canViewClinicPatients(userRole));
  // const [hasAddPatientsAccess] = useState(canAddClinicPatients(userRole));
  // useEffect(() => {
  //   const params = new URLSearchParams();
  //   params.set("title", title);

  //   setSearchParams(params, {
  //     replace: true,
  //   });
  // }, [title, setSearchParams]);

  const handleChange = (_: any, newValue: string) => {
    setType(newValue);
    setPage(0);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return urlLoaded ? (
    <>
      <StyledHeader>
        <Box sx={{ ...HeaderLeftContent, gap: 1 }}>
          <IconButton onClick={handleBack}>
            <ArrowBack htmlColor="#637E85" />
          </IconButton>
          <Typography fontSize={30} fontWeight="bold">
            {title}
          </Typography>
        </Box>
        {hasModifyAccess && (
          <Box sx={HeaderRightContent}>
            {type === "clinicians" && (
              <Box sx={HeaderRightContent}>
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  onClick={() => setShowModal(true)}
                >
                  Add Clinician
                </Button>
              </Box>
            )}
            {/* {hasAddPatientsAccess && type === "patients" && (
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => setShowModal(true)}
              >
                Add Patient
              </Button>
            )} */}
          </Box>
        )}
      </StyledHeader>
      <Box sx={{ borderTop: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab
            label="Clinic Overview"
            value="clinicOverview"
            {...a11yProps(0)}
          />
          <StyledTab label="Clinicians" value="clinicians" {...a11yProps(1)} />
          {hasPatientsAccess && (
            <StyledTab label="Patients" value="patients" {...a11yProps(1)} />
          )}
        </StyledTabs>
      </Box>
      <Box sx={{ mt: "24px" }}>
        <MainTabPanel value={type} index={"clinicOverview"}>
          <ClinicOverview />
        </MainTabPanel>
        <MainTabPanel value={type} index={"clinicians"}>
          <Clinicians
            type={type}
            page={page}
            setPage={setPage}
            searchText={searchText}
            setSearchText={setSearchText}
            showModal={showModal}
            setShowModal={setShowModal}
            hasModifyAccess={hasModifyAccess}
          />
        </MainTabPanel>
        {hasPatientsAccess && (
          <MainTabPanel value={type} index={"patients"}>
            <Patients
              type={type}
              page={page}
              setPage={setPage}
              searchText={searchText}
              setSearchText={setSearchText}
              showModal={showModal}
              setShowModal={setShowModal}
              hasModifyAccess={hasModifyAccess}
            />
          </MainTabPanel>
        )}
      </Box>
      <ClinicsUrlSetter
        title={title}
        page={page}
        type={type}
        searchText={searchText}
      />
    </>
  ) : (
    <ClinicsUrlLoader
      setPage={setPage}
      setType={setType}
      setSearchText={setSearchText}
      setUrlLoaded={setUrlLoaded}
    />
  );
};

export default ClinicDetails;
