const symptomLookup: any = {
  bradykinesia: "Bradykinesia",
  rigidity: "Rigidity",
  tremor: "Tremor",
  onOff: "On-Off times or Fluctuations",
  off: "Unpredictable 'off'",
  dyskinesia: "Dyskinesia",
  axialDeformities: "Axial Deformities",
  dysphagia: "Dysphagia",
  posturalInstability: "Postural Instability",
  fallingDown: "Falling Down",
  others: "Others",
};

const colorArray = ["#76B700", "#FACA15", "#F88D22", "#F05252", "#70AE71"];

export const troublesomeSymptomsFormatter = (data: any[]) => {
  let total = 0;
  data.forEach((item) => {
    total += +item.count;
  });
  data.sort((a, b) => +b.count - +a.count);
  const formattedData = data.map((item, index) => {
    let percent = 0;
    if (total > 0) {
      percent = (item.count / total) * 100;
    }
    return {
      name: symptomLookup[item.value] || "",
      value: +item.count,
      percent,
      color: colorArray[index % 5],
    };
  });
  return formattedData.slice(0, 5);
};

export const insightsFormatter = (data: any) => {
  let minutesSum =
    +data?.offMinutes + +data?.onMinutes + +data?.withDykinesiaMinutes;
  let offWidth = 0,
    onWidth = 0,
    dyskinesiaWidth = 0;
  if (minutesSum > 0) {
    offWidth = (+data?.offMinutes / minutesSum) * 100;
    onWidth = (+data?.onMinutes / minutesSum) * 100;
    dyskinesiaWidth = (+data?.withDykinesiaMinutes / minutesSum) * 100;
    offWidth = +offWidth.toFixed(2);
    onWidth = +onWidth.toFixed(2);
    dyskinesiaWidth = +dyskinesiaWidth.toFixed(2);
  }
  const offMinutes = +(+data?.offMinutes).toFixed(2);
  let offMinutesStr =
    offMinutes < 60
      ? offMinutes + " mins"
      : Math.floor(offMinutes / 60) +
        " hr " +
        (offMinutes % 60).toFixed(2) +
        " mins";
  const onMinutes = +(+data?.onMinutes).toFixed(2);
  let onMinutesStr =
    onMinutes < 60
      ? onMinutes + " mins"
      : Math.floor(onMinutes / 60) +
        " hr " +
        (onMinutes % 60).toFixed(2) +
        " mins";
  const withDykinesiaMinutes = +(+data?.withDykinesiaMinutes).toFixed(2);
  let withDykinesiaStr =
    withDykinesiaMinutes < 60
      ? withDykinesiaMinutes + " mins"
      : Math.floor(withDykinesiaMinutes / 60) +
        " hr " +
        (withDykinesiaMinutes % 60).toFixed(2) +
        " mins";
  return {
    minutesSum,
    offWidth,
    onWidth,
    dyskinesiaWidth,
    offMinutes: offMinutesStr,
    onMinutes: onMinutesStr,
    withDykinesiaMinutes: withDykinesiaStr,
  };
};

// export const transformGraphData = (data: any[]) => {
//   const monthlyInsightsData = [];
//   for (let i = 0; i < data.length; i += 7) {
//     const weekData = data.slice(i, i + 7);
//     const startDate = weekData[0].date;
//     const insightsData = { startDate };

//     weekData.forEach((day, index) => {
//       const dayKey = `day${index + 1}`;
//       //@ts-ignore
//       insightsData[dayKey] = day.monthlyInsights;
//     });
//     monthlyInsightsData.push(insightsData);
//   }
//   return monthlyInsightsData;
// };

export const transformGraphData = (data: any[]) => {
  const dailyInsightsData = [];
  for (let i = 0; i < data.length; i++) {
    const dayData = data[i];
    const startDate = dayData.date;
    const insightsData = {
      startDate,
      value: dayData.monthlyInsights,
    };
    dailyInsightsData.push(insightsData);
  }
  return dailyInsightsData;
};
