import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";

import ChangePassword from "./Components/Auth/ChangePassword";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import ResetPassword from "./Components/Auth/ResetPassword";
import SetPassword from "./Components/Auth/SetPassword";
import SignIn from "./Components/Auth/SignIn";
import AppLayout from "./Components/Layout/AppLayout";
import Administrators from "./Components/UserManagement/Administrators/Administrators";
import Participants from "./Components/UserManagement/Participants/Participants";
import { UnAuthenticatedRoute, AuthenticatedRoute } from "./utils/Route";
import OnboardingBuilder from "./Components/Onboarding/Builder/Builder";
import CMS from "./Components/CMS/CMS";
import LearningModuleDetails from "./Components/CMS/Modules/LearningModule/LearningModuleDetails";
import Pages from "./Components/CMS/Modules/EducationModule.tsx/Pages";
import Reviews from "./Components/CMS/Modules/EducationModule.tsx/Feedback/Reviews";
import CMSPreviewWrapper from "./Components/CMS/PreviewV2/CMSPreviewWrapper";
import CMSBuilder from "./Components/CMS/BuilderV2/CMSBuilder";
import WebviewWrapper from "./Components/CMS/Webview/WebviewWrapper";
import QuestionPreview from "./Components/QuestionPreview/QuestionPreview";
import QuestionBuilderContext from "./Components/QuestionBuilder/QuestionBuilder";
import Questions from "./Components/Question/Questions";
import ExerciseCollection from "./Components/ExerciseCollection/ExerciseCollection";
import ExerciseCollectionDetails from "./Components/ExerciseCollection/ExerciseCollectionDetails";
import Health from "./Components/PatientDetails/Health/Health";
import Dashboard from "./Components/Dashboard/Dashboard";
import SectionBuilder from "./Components/ExerciseCollection/Sections/SectionBuilder";
import ExerciseBuilder from "./Components/ExerciseCollection/ExerciseBuilder/ExerciseBuilder";
import Medication from "./Components/Medication/Medication";
import Prescription from "./Components/PatientDetails/Health/Block/Medication/Prescription/Prescription";
import OnboardingPreview from "./Components/Onboarding/Preview/Preview";
import Clinics from "./Components/Clinics/Clinics";
import ClinicDetails from "./Components/Clinics/ClinicDetails";
import {
  canAccessMyClinic,
  canViewAdministrators,
  canViewClinicDetails,
  canViewClinicRequests,
  canViewClinics,
  canViewCMS,
  canViewDailyLog,
  canViewExercise,
  canViewMedication,
  canViewOnboarding,
  canViewPatients,
  canViewQuestionnaire,
  canViewSymptoms,
} from "./utils/roles";
import PatientRequests from "./Components/PatientRequests/PatientRequest";
import DeleteAccountSteps from "./Components/Settings/DeleteAccountSteps";

const routes = createBrowserRouter([
  {
    path: "/auth",
    element: (
      <UnAuthenticatedRoute>
        <Outlet />
      </UnAuthenticatedRoute>
    ),
    children: [
      {
        path: "login",
        element: <SignIn />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password/:token",
        element: <ResetPassword />,
      },
      {
        path: "set-password",
        element: <SetPassword />,
      },
    ],
  },
  {
    path: "/app",
    element: (
      <AuthenticatedRoute>
        <AppLayout />
      </AuthenticatedRoute>
    ),
    children: [
      {
        path: "dashboard",
        element: (
          <AuthenticatedRoute>
            <Dashboard />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "change-password",
        element: (
          <AuthenticatedRoute>
            <ChangePassword />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "administrators",
        element: (
          <AuthenticatedRoute allowedRoles={canViewAdministrators}>
            <Administrators />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "exercise",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute allowedRoles={canViewExercise}>
                <ExerciseCollection />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ":id/categories",
            element: (
              <AuthenticatedRoute allowedRoles={canViewExercise}>
                <ExerciseCollectionDetails />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ":id/categories/:categoryId/sections",
            element: (
              <AuthenticatedRoute allowedRoles={canViewExercise}>
                <SectionBuilder />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "builder",
            element: (
              <AuthenticatedRoute allowedRoles={canViewExercise}>
                <ExerciseBuilder />
              </AuthenticatedRoute>
            ),
          },
        ],
      },
      {
        path: "medication",
        element: (
          <AuthenticatedRoute allowedRoles={canViewMedication}>
            <Medication />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "clinics",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute allowedRoles={canViewClinics}>
                <Clinics />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ":id",
            element: (
              <AuthenticatedRoute allowedRoles={canViewClinicDetails}>
                <ClinicDetails />
              </AuthenticatedRoute>
            ),
          },
        ],
      },
      {
        path: "clinic",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute allowedRoles={canAccessMyClinic}>
                <ClinicDetails />
              </AuthenticatedRoute>
            ),
          },
        ],
      },
      {
        path: "patient-requests",
        element: (
          <AuthenticatedRoute allowedRoles={canViewClinicRequests}>
            <PatientRequests />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "participants",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute allowedRoles={canViewPatients}>
                <Participants />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ":id",
            element: (
              <AuthenticatedRoute allowedRoles={canViewPatients}>
                <Health />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ":id/prescription",
            element: (
              <AuthenticatedRoute allowedRoles={canViewPatients}>
                <Prescription />
              </AuthenticatedRoute>
            ),
          },
        ],
      },
      {
        path: "onboarding",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute allowedRoles={canViewOnboarding}>
                <OnboardingPreview type="onboarding" />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "builder",
            element: (
              <AuthenticatedRoute allowedRoles={canViewOnboarding}>
                <OnboardingBuilder />
              </AuthenticatedRoute>
            ),
          },
        ],
      },
      {
        path: "daily-log",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute allowedRoles={canViewDailyLog}>
                <OnboardingPreview type="daily_log" />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "builder",
            element: (
              <AuthenticatedRoute allowedRoles={canViewDailyLog}>
                <OnboardingBuilder />
              </AuthenticatedRoute>
            ),
          },
        ],
      },
      {
        path: "symptoms",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute allowedRoles={canViewSymptoms}>
                <OnboardingPreview type="daily_symptoms" />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "builder",
            element: (
              <AuthenticatedRoute allowedRoles={canViewSymptoms}>
                <OnboardingBuilder />
              </AuthenticatedRoute>
            ),
          },
        ],
      },
      {
        path: "cms",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute allowedRoles={canViewCMS}>
                <CMS />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "learning/:id",
            element: (
              <AuthenticatedRoute allowedRoles={canViewCMS}>
                <LearningModuleDetails />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ":id",
            element: (
              <AuthenticatedRoute allowedRoles={canViewCMS}>
                <Pages />
              </AuthenticatedRoute>
            ),
          },

          {
            path: "review/:id",
            element: (
              <AuthenticatedRoute allowedRoles={canViewCMS}>
                <Reviews />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "builder/:id",
            element: (
              <AuthenticatedRoute allowedRoles={canViewCMS}>
                <CMSBuilder />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "preview/:id",
            element: (
              <AuthenticatedRoute allowedRoles={canViewCMS}>
                <CMSPreviewWrapper />
              </AuthenticatedRoute>
            ),
          },
        ],
      },
      {
        path: "question",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute allowedRoles={canViewQuestionnaire}>
                <Questions />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "builder",
            element: (
              <AuthenticatedRoute allowedRoles={canViewQuestionnaire}>
                <QuestionBuilderContext />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "preview",
            element: (
              <AuthenticatedRoute allowedRoles={canViewQuestionnaire}>
                <QuestionPreview />
              </AuthenticatedRoute>
            ),
          },
          // {
          //   path: "score",
          //   element: (
          //     <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
          //       <ScoreList />
          //     </AuthenticatedRoute>
          //   ),
          // },
          // {
          //   path: "score/create",
          //   element: (
          //     <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
          //       <CreateStore />
          //     </AuthenticatedRoute>
          //   ),
          // },
        ],
      },
    ],
  },
  {
    path: "/lesson/read/:id",
    element: <WebviewWrapper />,
  },
  {
    path: "/account-deletion-steps",
    element: <DeleteAccountSteps />,
  },
  {
    path: "/",
    element: <Navigate to="/app/dashboard" replace />,
  },
  {
    path: "*",
    element: <Navigate to="/app/dashboard" replace />,
  },
]);

export default routes;
