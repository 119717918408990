import React, { useState } from "react";
import { AxiosResponse } from "axios";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import * as yup from "yup";
import { FieldArray, Formik } from "formik";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../Common/styles/modal";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import http from "../../../utils/http";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useAppSelector } from "../../../Redux/hooks";
import { Add, Delete } from "@mui/icons-material";
import { isValidPhoneNumber } from "libphonenumber-js";

let schema = yup.object().shape({
  firstName: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("First Name is Required"),
  lastName: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Last Name is Required"),
  phoneNumber: yup
    .string()
    .test("phone-number-test", "Enter a valid phone number", (value) => {
      let valid = false;
      try {
        if (value) {
          valid = isValidPhoneNumber("+" + value);
        }
      } catch (err) {
      } finally {
        return valid;
      }
    })
    .required("Phone Number is Required"),
  email: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .email("Please enter a valid Email ID.")
    .required("Email ID is Required"),
  order: yup.array().of(
    yup.object().shape({
      email: yup.string().email("Please enter a valid Email ID.").optional(),
      enabled: yup.boolean().optional(),
    })
  ),
  npiNumber: yup
    .string()
    .transform((value) => (value ? value.trim() : value))
    .required("NPI Number is Required"),
  active: yup.boolean().required("Active state should be required"),
});

type Props = {
  showModal: boolean;
  closeModal: () => void;
  refreshPage: () => void;
};

const AddClinicianModal = ({ showModal, closeModal, refreshPage }: Props) => {
  const [submitLoader, setSubmitLoader] = useState(false);

  const { id } = useParams();
  const { role } = useAppSelector((state) => state.user);

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      let res: AxiosResponse;
      let url;
      if (role === "clinician") {
        url = `/clinic/clinician`;
      } else {
        url = `/clinic/${id}/clinician`;
      }

      const body = { ...values, phoneNumber: "+" + values.phoneNumber };
      const orderWithEmail = body.order.filter(
        (item: any) => item.email.trim() !== ""
      );
      body.orderEmailRecipients = orderWithEmail;
      delete body.order;
      res = await http.post(url, body);
      toastMessage("success", res.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader title="Add New Clinician" onCloseClick={closeModal} />

        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            order: [{ email: "", enabled: false }],
            active: false,
            npiNumber: "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            handleSubmit,
            getFieldProps,
            setFieldValue,
            setFieldTouched,
            values,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="first-name">
                    First Name <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="first-name"
                    {...getFieldProps("firstName")}
                    error={
                      touched?.firstName && errors?.firstName ? true : false
                    }
                    helperText={
                      touched?.firstName && errors?.firstName
                        ? (errors?.firstName as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="last-name">
                    Last Name <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="last-name"
                    {...getFieldProps("lastName")}
                    error={touched?.lastName && errors?.lastName ? true : false}
                    helperText={
                      touched?.lastName && errors?.lastName
                        ? (errors?.lastName as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="email">
                    Email ID <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="email"
                    {...getFieldProps("email")}
                    error={touched?.email && errors?.email ? true : false}
                    helperText={
                      touched?.email && errors?.email
                        ? (errors?.email as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>

              <Typography
                variant="subtitle2"
                fontWeight="bold"
                textTransform={"capitalize"}
                sx={{
                  color: "#355962",
                  mb: 2,
                }}
              >
                Email Recipients
              </Typography>
              {values.order.length > 0 && (
                <FieldArray name="order">
                  {({ remove, push }) => (
                    <>
                      {values?.order?.map((mail: any, index: number) => (
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                          }}
                          key={index}
                        >
                          <FormControl sx={InputWrapper}>
                            <FormLabel sx={LabelStyle} htmlFor="email-address">
                              Email Address
                            </FormLabel>
                            <TextField
                              fullWidth
                              key={index}
                              id="email-address"
                              value={mail.email}
                              onChange={(e) =>
                                setFieldValue(
                                  `order.${index}.email`,
                                  e.target.value
                                )
                              }
                              error={
                                touched?.order?.[index]?.email &&
                                // @ts-ignore
                                errors?.order?.[index]?.email
                                  ? true
                                  : false
                              }
                              helperText={
                                touched?.order?.[index]?.email &&
                                // @ts-ignore
                                errors?.order?.[index]?.email
                                  ? // @ts-ignore
                                    errors?.order?.[index]?.email
                                  : " "
                              }
                            />
                          </FormControl>
                          <FormControl
                            sx={{
                              ...InputWrapper,
                              justifyContent: "flex-start",
                            }}
                          >
                            <FormLabel sx={LabelStyle}>Enabled</FormLabel>
                            <Box mt={1}>
                              <Switch
                                checked={mail.enabled}
                                onChange={(e) =>
                                  setFieldValue(
                                    `order.${index}.enabled`,
                                    e.target.checked
                                  )
                                }
                              />
                            </Box>
                          </FormControl>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              onClick={() =>
                                push({
                                  email: "",
                                  enabled: false,
                                })
                              }
                            >
                              <Add />
                            </IconButton>
                            <IconButton
                              disabled={values?.order.length === 1}
                              color="error"
                              onClick={() => remove(index)}
                            >
                              <Delete />
                            </IconButton>
                          </Box>
                        </Box>
                      ))}
                    </>
                  )}
                </FieldArray>
              )}
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl
                  sx={{
                    ...InputWrapper,
                    justifyContent: "unset",
                    mb: 3,
                  }}
                >
                  <FormLabel sx={LabelStyle} htmlFor="phone-number">
                    Phone Number <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <PhoneInput
                    inputProps={{
                      id: "phone-number",
                    }}
                    value={values.phoneNumber}
                    onBlur={() => {
                      setFieldTouched("phoneNumber", true);
                    }}
                    onChange={(value) => setFieldValue("phoneNumber", value)}
                    country={"us"}
                    inputStyle={{
                      width: "100%",
                      height: "52px",
                      borderRadius: "6px",
                      fontSize: "16px",
                    }}
                    isValid={
                      touched?.phoneNumber && errors?.phoneNumber ? false : true
                    }
                  />
                  {touched?.phoneNumber && errors?.phoneNumber && (
                    <FormHelperText error>
                      {errors?.phoneNumber as string}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="active">
                    Active <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Select
                    fullWidth
                    id="active"
                    sx={{ mb: 2 }}
                    value={values.active}
                    onChange={(e) => {
                      setFieldValue("active", e.target.value);
                    }}
                  >
                    {[
                      { value: true, label: "Yes" },
                      { value: false, label: "No" },
                    ].map((item: any, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched?.active && errors?.active
                      ? (errors?.active as string)
                      : " "}
                  </FormHelperText>
                  {/* <TextField
                    id="active"
                    {...getFieldProps("active")}
                    error={touched?.email && errors?.email ? true : false}
                    helperText={
                      touched?.email && errors?.email
                        ? (errors?.email as string)
                        : " "
                    }
                  /> */}
                </FormControl>
                <FormControl
                  sx={{
                    ...InputWrapper,
                    justifyContent: "unset",
                  }}
                >
                  <FormLabel sx={LabelStyle} htmlFor="npi-number">
                    NPI <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="npi-number"
                    {...getFieldProps("npiNumber")}
                    error={
                      touched?.npiNumber && errors?.npiNumber ? true : false
                    }
                    helperText={
                      touched?.npiNumber && errors?.npiNumber
                        ? (errors?.npiNumber as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={ModalActionButtonStyles}>
                {!submitLoader ? (
                  <>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddClinicianModal;
