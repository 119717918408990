import { Box, Typography } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
const colorByValue = (value: any) => {
  if (value === "Skipped") return "#F88D22";
  else if (value === "Taken") return "#76B700";
  //   else if (value.formattedValue <= 70) return "#FACA15";
  //   else if (value.formattedValue <= 100) return "#76B700";
  else return "#70AE71";
};
const PatientAdherence = ({ data }: any) => {
  return (
    <Box
      sx={{
        flex: 1,
        minWidth: "580px",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        padding: "15px 20px",
        mb: 2.5,
        boxShadow: " 0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
      }}
    >
      <Box>
        <Typography
          fontWeight={"medium"}
          fontSize={"22px"}
          color="#355962"
          marginBottom={"8px"}
        >
          {data?.title || "Medication Adherence"}
        </Typography>
      </Box>
      <Box sx={{ height: "50vh", maxHeight: "400px" }}>
        <ResponsiveBar
          data={(data?.data || [])?.map((item: any) => ({
            label: item?.label,
            percent: item?.percent,
          }))}
          keys={["percent"]}
          indexBy="label"
          colors={(d: any) => colorByValue(d.indexValue)}
          margin={{ top: 40, right: 10, bottom: 50, left: 40 }}
          padding={0.9}
          valueScale={{ type: "linear", min: 0, max: 100, clamp: true }}
          indexScale={{ type: "band" }}
          gridYValues={[0, 20, 40, 60, 80, 100]}
          theme={{
            fontSize: 14,
            grid: {
              line: {
                strokeDasharray: "6 6",
              },
            },
            axis: {
              domain: {
                line: {
                  stroke: "#E0E3EB",
                },
              },
              ticks: {
                text: {
                  fill: "#355962",
                  fontSize: 14,
                  fontWeight: 550,
                },
              },
              legend: {
                text: {
                  fill: "#9CA3AF",
                  fontSize: 14,
                  fontWeight: 500,
                },
              },
            },
          }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 12,
            tickRotation: 0,
            legendPosition: "middle",
            legendOffset: 40,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 7,
            tickRotation: 0,
            tickValues: [0, 20, 40, 60, 80, 100],
            format: (value) => `${value}%`,
            legendPosition: "middle",
            legendOffset: -70,
          }}
          enableLabel={false}
          ariaLabel="Medication Adherence"
        />
      </Box>
    </Box>
  );
};

export default PatientAdherence;
