import { Box, Button, Divider, SxProps, Typography } from "@mui/material";
import { StyledTab, StyledTabs, a11yProps } from "../../Common/UI/TabPanel";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { setHealthType } from "../../../Redux/reducers/healthSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import AssignQuestionnaireModal from "./Block/Questionnaire/QuestionBlock/AssignQuestionnaireModal";
import AddIcon from "@mui/icons-material/Add";

const Container: SxProps = {
  p: 2,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "8px 8px 0px 0px",
  backgroundColor: "#ffffff",
};

const tabs = [
  {
    key: "patient_overview",
    label: "Patient Overview",
  },
  {
    key: "pkg_summary",
    label: "PKG Summary",
  },
  {
    key: "food",
    label: "Food",
  },
  {
    key: "medication",
    label: "Medication",
  },
  {
    key: "questionnaire",
    label: "Questionnaire",
  },
  {
    key: "onboarding",
    label: "Onboarding Questionnaire",
  },
  {
    key: "daily_log",
    label: "Daily Log",
  },
  {
    key: "symptoms",
    label: "Symptoms",
  },
  // {
  //   key: "tests",
  //   label: "Test",
  // },

  {
    key: "exercise",
    label: "Exercises",
  },
  {
    key: "reports",
    label: "Reports",
  },
];

const HealthTabs: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { type } = useAppSelector((state) => state.health);
  const [showModal, setShowModal] = useState<boolean>(false);

  const tabChange = (_1: any, val: string) => {
    dispatch(setHealthType(val));
  };

  const handleNavigate = () => {
    navigate(`/app/participants/${id}/prescription`);
  };
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Box sx={Container}>
        <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
          Health
        </Typography>
        {type === "medication" && (
          <Button variant="contained" onClick={handleNavigate}>
            Prescribe Medication
          </Button>
        )}
        {type === "questionnaire" && (
          <Button
            variant="contained"
            onClick={openModal}
            startIcon={<AddIcon />}
          >
            Assign Questionnaire
          </Button>
        )}
      </Box>
      <Divider sx={{ color: "#E5E7EB" }} />
      <StyledTabs value={type} onChange={tabChange}>
        {tabs.map((tab, index) => (
          <StyledTab
            key={tab.key}
            label={tab.label}
            value={tab.key}
            {...a11yProps(index)}
          />
        ))}
      </StyledTabs>
      {showModal && (
        <AssignQuestionnaireModal
          showModal={showModal}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default HealthTabs;
