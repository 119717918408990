import { Box } from "@mui/material";

import {
  a11yProps,
  MainTabPanel,
  StyledTab,
  StyledTabs,
} from "../../Common/UI/TabPanel";
import ParticipantHeader from "./ParticipantHeader";
import ParticipantList from "./ParticipantList";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setParticipantsType,
  // setUrlLoadedFalse,
} from "../../../Redux/reducers/participantsSlice";
import ParticipantUrlLoader from "./ParticipantUrlLoader";
import ParticipantUrlSetter from "./ParticipantUrlSetter";
import { useEffect } from "react";
import { reset } from "../../../Redux/reducers/healthSlice";

const Participants = () => {
  const dispatch = useAppDispatch();
  const { type, urlLoaded } = useAppSelector((state) => state.participants);

  const handleChange = (_: any, newType: string) => {
    dispatch(setParticipantsType(newType));
  };

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setUrlLoadedFalse());
  //   };
  // }, [dispatch]);

  return urlLoaded ? (
    <>
      <ParticipantHeader />
      <Box sx={{ borderTop: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab label="Active Patients" value="active" {...a11yProps(0)} />
          <StyledTab
            label="Archived Patients"
            value="inactive"
            {...a11yProps(1)}
          />
          {/* {hasAdminAccess && (
            <StyledTab
              label="Unregistered Participants"
              value="unregistered"
              {...a11yProps(2)}
            />
          )} */}
        </StyledTabs>
      </Box>
      <Box sx={{ mt: "24px" }}>
        <MainTabPanel value={type} index={type}>
          <ParticipantList />
        </MainTabPanel>
      </Box>
      <ParticipantUrlSetter />
    </>
  ) : (
    <ParticipantUrlLoader />
  );
};

export default Participants;
