import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  LinearProgress,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import {
  NoDataContainer,
  StyledTableCell,
  TablePaginationStyle,
  pageSize,
  paginationLabel,
} from "../../../../Common/styles/table";
import { MoreVert } from "@mui/icons-material";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { errorToastMessage } from "../../../../../utils/toast";
import { useAppSelector } from "../../../../../Redux/hooks";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";

const Reports = () => {
  const [loading, setLoading] = useState(false);
  const [reportsData, setReportsData] = useState<any>([]);
  const [reportsDataCount, setReportsDataCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [page, setPage] = useState(parseInt("0"));
  const open = Boolean(anchorEl);
  const { startDate, endDate } = useAppSelector((state) => state.health);
  const { id } = useParams();

  useEffect(() => {
    const fetchReportsData = async () => {
      try {
        if (startDate && endDate) {
          setLoading(true);
          const start = DateTime.fromFormat(startDate, "dd-LL-yyyy").startOf(
            "day"
          );
          let end = DateTime.fromFormat(endDate, "dd-LL-yyyy").endOf("day");
          const res: AxiosResponse = await http.get(
            `/reports/files?page=${
              page + 1
            }&size=${pageSize}&userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
          );
          const data = res.data?.data;
          const newData = data?.rows?.map((row: any) => ({
            id: row?.id,
            name: "Reports " + row?.startDate,
            date: row?.startDate,
            reportUrls: row?.reportUrls,
          }));
          setReportsData(newData || []);
          setReportsDataCount(data?.count);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchReportsData();
  }, [startDate, endDate, id, page]);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  const downloadFile = () => {
    window.open(selectedRow?.reportUrls[0], "_blank");
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ overflowX: "scroll" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Report Name</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          {!loading && reportsData?.length > 0 && (
            <>
              <TableBody>
                {reportsData?.map((row: any) => (
                  <TableRow key={row?.id}>
                    <StyledTableCell sx={{ color: "#327091" }}>
                      <Typography fontWeight={600} fontSize="14px">
                        {row?.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>{row?.date}</StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton onClick={(e) => handleMenuClick(e, row)}>
                        <MoreVert />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
              {reportsDataCount > pageSize && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={reportsDataCount}
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={paginationLabel}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </>
          )}
        </Table>
      </Box>
      {!loading && reportsData.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: "400px",
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={downloadFile}>
          <ListItemText>Download</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Reports;
