import { PersonOutlineOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Skeleton,
  SxProps,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { setHealthUser, setToggle } from "../../../Redux/reducers/healthSlice";
import http from "../../../utils/http";
import AddIcon from "@mui/icons-material/Add";
import AddCaregiverModal from "./Modal/AddCaregiverModal";
import { AxiosResponse } from "axios";
import AddOrderModal from "./Modal/AddOrderModal";
import CaregiverTabs from "./Modal/CaregiverTabs";
import { canOrderPkg, canViewOrder } from "../../../utils/roles";

const HeaderStyle: SxProps = {
  height: "112px",
  width: "100%",
  borderRadius: "16px",
  background: "#FEFEFE",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  mb: 3,
  display: "flex",
  alignItems: "center",
  p: 2,
  justifyContent: "space-between",
};

const InfoContainer: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  pr: 2,
  mr: 2,
};

const BorderedInfoContainer: SxProps = {
  ...InfoContainer,
  borderRight: "1px solid",
  borderColor: "#E0E3EB",
};

const text1: SxProps = {
  color: "#111928",
  fontSize: "18px",
  fontWeight: "600",
  lineHeight: "150%",
  mb: 1,
  textTransform: "capitalize",
};

const text2: SxProps = {
  color: "#6B7280",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "150%",
};

const HealthHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const { userData, toggleReload } = useAppSelector((state) => state.health);
  const { id } = useParams();
  const [showOrderModal, setShowOrderModal] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<any>({});
  const [showDetailModal, setDetailModal] = useState(false);
  const [showAddModal, setAddModal] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const { role } = useAppSelector((state) => state.user);
  const [pkgOrder] = useState(canOrderPkg(role));
  const [viewOrder] = useState(canViewOrder(role));
  const [addressData, setAddressData] = useState<any>(null);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        dispatch(setHealthUser(null));
        const res = await http.get(`/participants/${id}/profile`);
        const response = res.data.data;
        const data = {
          clinicId: response?.clinicId,
          email: response?.user?.email,
          phoneNumber: response?.user?.phoneNumber,
          name: response?.user?.firstName + " " + response?.user?.lastName,
          verified: response?.user?.isEmailVerified,
          id: response?.user?.id,
          gender: response?.gender,
          dob: response?.dob,
          caregiver: response?.user?.userCaregiver
            ? {
                id: response?.user?.userCaregiver?.careGiver?.id,
                firstName: response?.user?.userCaregiver?.careGiver?.firstName,
                lastName: response?.user?.userCaregiver?.careGiver?.lastName,
                name: response?.user?.userCaregiver?.careGiver?.firstName
                  ? response?.user?.userCaregiver?.careGiver?.firstName +
                    " " +
                    response?.user?.userCaregiver?.careGiver?.lastName
                  : "",
                email: response?.user?.userCaregiver?.careGiver?.email,
                phoneNumber:
                  response?.user?.userCaregiver?.careGiver?.phoneNumber,
                verified:
                  response?.user?.userCaregiver?.careGiver?.isEmailVerified,
              }
            : null,
        };
        const address = {
          city: response?.addressCity,
          isoCode: response?.addressCountryISOCode,
          addressLine1: response?.addressLine1,
          addressLine2: response?.addressLine2,
          postCode: response?.addressPostcode,
          state: response?.addressState,
        };
        if (viewOrder) {
          const res2: AxiosResponse = await http.get(
            `/clinic/${data?.clinicId}/orders?userId=${id}`
          );
          const response2 = res2.data.data;
          if (response2) {
            const data2 = {
              orderId: response2?.id || "",
              addressLine1: response2?.addressLine1 || "",
              // addressLine2: response2?.addressLine2 || "",
              addressCity: response2?.addressCity || "",
              addressState: response2?.addressState || "",
              addressPostcode: response2?.addressPostcode || "",
              addressCountryISOCode: response2?.addressCountryISOCode || "",
              recordNumber: response2?.recordNumber || "",
              orderNotes: response2?.orderNotes || "",
              clinicOrderNotes: response2?.clinicOrderNotes || "",
              deepBrainStimulator: response2?.deepBrainStimulator || false,
              infusionTherapy: response2?.infusionTherapy || "",
              reminderDemo: response2?.reminderDemo || false,
              shipDate: response2?.shipDate || "",
              expirationDate: response2?.expirationDate || "",
              pkgDueDate: response2?.pkgDueDate || "",
              externalId: response2?.externalId || "",
              // addressStreet: response2?.street || "",
            };
            setOrderData(data2);
          } else {
            setOrderData({});
          }
        }
        dispatch(setHealthUser(data));
        setAddressData(address);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [id, setLoading, dispatch, toggleReload, viewOrder]);

  const refresh = () => {
    dispatch(setToggle());
  };
  const showAddCaregiverModal = () => {
    setAddModal(true);
  };

  const showCaregiverDetailsModal = () => {
    setDetailModal(true);
  };
  const openOrderModal = () => {
    setShowOrderModal(true);
  };
  const closeOrderModal = () => {
    setShowOrderModal(false);
  };
  const reinvite = async () => {
    try {
      setSubmitLoader(true);
      let res: AxiosResponse = await http.post(
        `/caregiver/clinic/resend-invite`,
        {
          userId: id,
        }
      );
      toastMessage("success", res.data.message);
      setSubmitLoader(false);
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Box sx={HeaderStyle}>
      <Box sx={{ display: "flex" }}>
        <Avatar
          variant={"square"}
          sx={{
            height: "80px",
            width: "80px",
            bgcolor: "lightgray",
            color: "#000",
            mr: 2,
          }}
        >
          <PersonOutlineOutlined fontSize="large" />
        </Avatar>
        <Box sx={BorderedInfoContainer}>
          {loading ? (
            <>
              <Skeleton
                variant="rectangular"
                sx={{ height: "27px", width: "100px", mb: 1 }}
              />
              <Skeleton
                variant="rectangular"
                sx={{ height: "18px", width: "100px" }}
              />
            </>
          ) : (
            <>
              <Typography sx={text1}>{userData?.name || "Username"}</Typography>
              <Typography sx={text2}>{userData?.email || "email"}</Typography>
            </>
          )}
        </Box>
        <Box sx={BorderedInfoContainer}>
          {loading ? (
            <>
              <Skeleton
                variant="rectangular"
                sx={{ height: "27px", width: "100px", mb: 1 }}
              />
              <Skeleton
                variant="rectangular"
                sx={{ height: "18px", width: "100px" }}
              />
            </>
          ) : (
            <>
              <Typography sx={text1}>{userData?.gender || "Gender"}</Typography>
              <Typography sx={text2}>Gender</Typography>
            </>
          )}
        </Box>
        <Box sx={BorderedInfoContainer}>
          {loading ? (
            <>
              <Skeleton
                variant="rectangular"
                sx={{ height: "27px", width: "100px", mb: 1 }}
              />
              <Skeleton
                variant="rectangular"
                sx={{ height: "18px", width: "100px" }}
              />
            </>
          ) : (
            <>
              <Typography sx={text1}>
                {userData?.dob || "Date of Birth"}
              </Typography>
              <Typography sx={text2}>Birthday</Typography>
            </>
          )}
        </Box>
        {orderData?.externalId && (
          <Box sx={BorderedInfoContainer}>
            {loading ? (
              <>
                <Skeleton
                  variant="rectangular"
                  sx={{ height: "27px", width: "100px", mb: 1 }}
                />
                <Skeleton
                  variant="rectangular"
                  sx={{ height: "18px", width: "100px" }}
                />
              </>
            ) : (
              <>
                <Typography sx={text1}>{orderData?.externalId}</Typography>
                <Typography sx={text2}>Order Id</Typography>
              </>
            )}
          </Box>
        )}
        <Box sx={InfoContainer}>
          {loading ? (
            <>
              <Skeleton
                variant="rectangular"
                sx={{ height: "27px", width: "100px", mb: 1 }}
              />
              <Skeleton
                variant="rectangular"
                sx={{ height: "18px", width: "100px" }}
              />
            </>
          ) : (
            <>
              {userData?.caregiver ? (
                <Button onClick={showCaregiverDetailsModal}>
                  {userData?.caregiver.name || "Caregiver Details"}
                </Button>
              ) : (
                <Button onClick={showAddCaregiverModal}>Add Caregiver</Button>
              )}
              <Typography sx={text2}>Caregiver</Typography>
            </>
          )}
        </Box>
      </Box>
      {pkgOrder && (
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={openOrderModal}
        >
          {orderData?.orderId ? "Update Order" : "Add Order"}
        </Button>
      )}

      {!loading && !userData?.verified && (
        <Box sx={{ marginLeft: "auto" }}>
          {submitLoader ? (
            <CircularProgress size={25} />
          ) : (
            <Button onClick={reinvite} variant="contained">
              Re-Invite Patient
            </Button>
          )}
        </Box>
      )}
      {showAddModal && (
        <AddCaregiverModal
          setAddModal={setAddModal}
          showAddModal={showAddModal}
          patient={userData?.name}
          caregiver={userData?.caregiver}
          refresh={refresh}
        />
      )}
      {showDetailModal && (
        <CaregiverTabs
          caregiver={userData?.caregiver}
          setDetailModal={setDetailModal}
          showAddCaregiverModal={showAddCaregiverModal}
        />
      )}
      {showOrderModal && (
        <AddOrderModal
          showModal={showOrderModal}
          closeModal={closeOrderModal}
          data={orderData}
          clinicId={userData?.clinicId}
          address={addressData}
          // id={userData.id}
          // refreshPage={refreshPage}
        />
      )}
    </Box>
  );
};

export default HealthHeader;
