import React, { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import "react-phone-input-2/lib/style.css";
import http from "../../../../../utils/http";
import { errorToastMessage } from "../../../../../utils/toast";
import { LabelStyle } from "../../../../Common/styles/form";
import { NoDataContainer } from "../../../../Common/styles/table";
import { useParams } from "react-router-dom";

const CaregiverPast = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);

  const { id } = useParams();

  useEffect(() => {
    const fetchClinicsData = async () => {
      try {
        setLoading(true);
        let url = `participants/${id}/caregiver`;

        const res: AxiosResponse = await http.get(url);
        const data = res.data?.data;
        const newData = data
          ?.filter((item: any) => item?.status === "inactive")
          .map((item: any) => {
            // if (item?.status && item?.status === "inactive") {
            return {
              id: item?.id,
              name: item?.careGiver?.firstName
                ? item?.careGiver?.lastName
                  ? `${item?.careGiver?.firstName} ${item?.careGiver?.lastName}`
                  : item?.careGiver?.firstName
                : "",
              email: item?.careGiver?.email,
              phoneNumber: item?.careGiver?.phoneNumber,
            };
            // }
          });
        setData(newData || []);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchClinicsData();
  }, [id]);

  return (
    <>
      {!loading && data?.length > 0 && (
        <Box>
          {data?.map((caregiver: any) => (
            <Box key={caregiver?.id}>
              <Box sx={{ display: "grid" }}>
                <Typography sx={LabelStyle}>{caregiver?.name}</Typography>
                <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
                  {caregiver?.email}
                </Typography>
                <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
                  {caregiver?.phoneNumber}
                </Typography>
              </Box>
              <Divider sx={{ mt: 2, mb: 2 }} />
            </Box>
          ))}
        </Box>
      )}
      {!loading && data.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default CaregiverPast;
