import { Box, LinearProgress, Typography } from "@mui/material";
import { LinearProgressStyle } from "./../../PatientDetails/Health/Block/style";

const TopSymptoms = ({ data }: any) => {
  return (
    <Box
      sx={{
        flex: 1,
        minWidth: "580px",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        padding: "15px 20px",
        mb: 2.5,
        boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
      }}
    >
      <Box>
        <Typography
          fontWeight={"medium"}
          fontSize={"22px"}
          color="#355962"
          marginBottom={"8px"}
        >
          {data?.title || "Top 5 Symptoms"}
        </Typography>
        <Box sx={{ mt: 5 }}>
          {data?.symptomsData.map((item: any) => (
            <Box
              sx={{
                ...LinearProgressStyle,
                "& .MuiLinearProgress-bar": {
                  backgroundColor: item.color,
                },
              }}
              key={item.name}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontWeight={"regular"}
                  fontSize={"18px"}
                  color="#355962"
                >
                  {item?.name}
                </Typography>
                <Typography
                  fontWeight={"regular"}
                  fontSize={"18px"}
                  color="#355962"
                >
                  {item?.value}
                </Typography>
              </Box>
              <LinearProgress variant="determinate" value={item?.percent} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default TopSymptoms;
