import { Box, Tooltip, Typography } from "@mui/material";
import { containerWrapper } from "../../../../Common/styles/flex";
import { linearProgressLabel, linearProgressText } from "./style";
import { useState } from "react";
import {
  LeftSideInsightsCustomTooltip,
  RightSideInsightsCustomTooltip,
} from "../../../../Common/UI/Tooltip";
import { NoDataContainer } from "../../../../Common/styles/table";

const Insights = ({ insightsData }: any) => {
  const [hoveredItem, setHoveredItem] = useState<string>("");
  return (
    <Box sx={{ ...containerWrapper, minHeight: "170px" }}>
      <Typography fontSize={22} fontWeight={600} color="#355962" mb={1}>
        PD Insights
      </Typography>
      {insightsData.minutesSum > 0 ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            mt: 5,
            height: "52px",
            border: "2px solid #e5eeee",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              width: insightsData?.offWidth + "%",
              backgroundColor: "#75BEC7",
            }}
            onMouseEnter={() => setHoveredItem("off")}
            onMouseLeave={() => setHoveredItem("")}
          >
            {insightsData?.offWidth > 10 && (
              <>
                <Tooltip title="OFF">
                  <Typography sx={linearProgressLabel}>OFF</Typography>
                </Tooltip>
                <Tooltip title={insightsData?.offMinutes}>
                  <Typography sx={linearProgressText}>
                    {insightsData?.offMinutes}
                  </Typography>
                </Tooltip>
              </>
            )}
            {hoveredItem === "off" && insightsData?.offWidth <= 10 && (
              <LeftSideInsightsCustomTooltip
                value="OFF"
                color="#75BEC7"
                label={insightsData?.offMinutes}
              />
            )}
          </Box>
          <Box
            sx={{
              textAlign: "center",
              width: insightsData?.onWidth + "%",
              backgroundColor: "#fff",
            }}
            onMouseEnter={() => setHoveredItem("on")}
            onMouseLeave={() => setHoveredItem("")}
          >
            {insightsData?.onWidth > 10 && (
              <>
                <Tooltip title="ON">
                  <Typography sx={linearProgressLabel}>ON</Typography>
                </Tooltip>
                <Tooltip title={insightsData?.onMinutes}>
                  <Typography sx={linearProgressText}>
                    {insightsData?.onMinutes}
                  </Typography>
                </Tooltip>
              </>
            )}
            {hoveredItem === "on" && insightsData?.onWidth <= 10 && (
              <LeftSideInsightsCustomTooltip
                value="ON"
                color="#fff"
                label={insightsData?.onMinutes}
              />
            )}
          </Box>
          <Box
            sx={{
              textAlign: "center",
              backgroundColor: "#F3AA69",
              width: insightsData?.dyskinesiaWidth + "%",
            }}
            onMouseEnter={() => setHoveredItem("dyskinesia")}
            onMouseLeave={() => setHoveredItem("")}
          >
            {insightsData?.dyskinesiaWidth > 10 && (
              <>
                <Tooltip title="with Dyskinesia">
                  <Typography
                    sx={{
                      ...linearProgressLabel,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    with Dyskinesia
                  </Typography>
                </Tooltip>
                <Tooltip title={insightsData?.withDykinesiaMinutes}>
                  <Typography sx={linearProgressText}>
                    {insightsData?.withDykinesiaMinutes}
                  </Typography>
                </Tooltip>
              </>
            )}
            {hoveredItem === "dyskinesia" &&
              insightsData?.dyskinesiaWidth <= 10 && (
                <RightSideInsightsCustomTooltip
                  value="with Dyskinesia"
                  color="#F3AA69"
                  label={insightsData?.withDykinesiaMinutes}
                />
              )}
          </Box>
        </Box>
      ) : (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
    </Box>
  );
};

export default Insights;
