import React, { useState } from "react";
import { AxiosResponse } from "axios";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import http from "../../../../../utils/http";
import { toastMessage, errorToastMessage } from "../../../../../utils/toast";
import { LabelStyle } from "../../../../Common/styles/form";

const CaregiverDetails: React.FC<any> = ({
  setDetailModal,
  caregiver,
  showAddCaregiverModal,
}) => {
  const [submitLoader, setSubmitLoader] = useState(false);

  const closeModal = () => {
    setDetailModal(false);
  };

  const reinvite = async () => {
    try {
      setSubmitLoader(true);
      let res: AxiosResponse = await http.post(
        `/caregiver/clinic/resend-invite`,
        {
          userId: caregiver?.id,
        }
      );
      toastMessage("success", res.data.message);
      setSubmitLoader(false);
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const editCaregiver = () => {
    closeModal();
    showAddCaregiverModal();
  };

  return (
    <Box>
      <Box sx={{ display: "grid", gap: 3 }}>
        <Box>
          <Typography sx={LabelStyle}>Caregiver Name</Typography>
          <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
            {caregiver.name || "-"}
          </Typography>
        </Box>
        <Box>
          <Typography sx={LabelStyle}>Email</Typography>
          <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
            {caregiver.email}
          </Typography>
        </Box>
        <Box>
          <Typography sx={LabelStyle}>Phone Number</Typography>
          <PhoneInput
            value={caregiver?.phoneNumber}
            copyNumbersOnly={false}
            containerStyle={{
              width: "100%",
            }}
            inputStyle={{
              border: "none",
              backgroundColor: "transparent",
              paddingLeft: 0,
              width: "fit-content",
              fontWeight: 400,
              fontSize: "14px",
            }}
            buttonStyle={{
              display: "none",
            }}
            disableDropdown={true}
            inputProps={{
              readOnly: true,
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          gap: 1,
        }}
      >
        <Button type="submit" variant="contained" onClick={editCaregiver}>
          Edit
        </Button>
        {!caregiver?.verified && (
          <>
            {!submitLoader ? (
              <>
                <Button onClick={reinvite} variant="contained">
                  Re-Invite
                </Button>
                <Button onClick={closeModal} variant="outlined">
                  Cancel
                </Button>
              </>
            ) : (
              <CircularProgress size={25} />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default CaregiverDetails;
