import React from "react";
import { Box, SxProps, Typography } from "@mui/material";
import { PKGSummaryMetrics } from "./PKGSummary";
import {
  DarkFeetIcon,
  GreenPillIcon,
  GreenRedPillIcon,
  GreyFeetIcon,
  GreyPillIcon,
  HappyIcon,
  NeutralIcon,
  NoFaceIcon,
  OrangePillIcon,
  OverSadIcon,
  SadIcon,
  SmDarkFeetIcon,
  SmGreenPillIcon,
  SmGreenRedPillIcon,
  SmGreyFeetIcon,
  SmGreyPillIcon,
  SmOrangePillIcon,
} from "./PKGIcons";

const calendarWrapper: SxProps = {
  border: 1,
  borderColor: "divider",
  bgcolor: "white",
  p: 2,
  borderRadius: "12px",
  minHeight: "253px",
};

const calendarContent: SxProps = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  mb: "10px",
};

const Symptoms = ({ value }: { value: string }) => {
  return value === "lot" ? (
    <OverSadIcon />
  ) : value === "little" ? (
    <SadIcon />
  ) : value === "slightly" ? (
    <NeutralIcon />
  ) : value === "none" ? (
    <HappyIcon />
  ) : (
    <NoFaceIcon />
  );
};

const Medication = ({ value }: { value: string }) => {
  return value === "none" ? (
    <GreyPillIcon />
  ) : value === "completed" ? (
    <GreenPillIcon />
  ) : value === "incomplete" ? (
    <GreenRedPillIcon />
  ) : value === "skipped" ? (
    <OrangePillIcon />
  ) : null;
};

const Activity = ({ value }: { value: boolean }) => {
  return value ? <DarkFeetIcon /> : <GreyFeetIcon />;
};

const SmMedication = ({ value }: { value: string }) => {
  return value === "none" ? (
    <SmGreyPillIcon />
  ) : value === "completed" ? (
    <SmGreenPillIcon />
  ) : value === "incomplete" ? (
    <SmGreenRedPillIcon />
  ) : value === "skipped" ? (
    <SmOrangePillIcon />
  ) : null;
};

const SmActivity = ({ value }: { value: boolean }) => {
  return value ? <SmDarkFeetIcon /> : <SmGreyFeetIcon />;
};

interface props {
  data: PKGSummaryMetrics[];
  type: string;
}

const Calendar: React.FC<props> = ({ data, type }) => {
  const weekdays = ["S", "M", "T", "W", "T", "F", "S"];

  return (
    <Box sx={calendarWrapper}>
      <Typography variant="h6" fontWeight={"medium"} color="#355962" mb={2}>
        {type}
      </Typography>
      <Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(7, 1fr)",
            mb: "12px",
          }}
        >
          {weekdays.map((day, i) => (
            <Typography
              fontSize={"19px"}
              fontWeight="400"
              color={"#0F1310"}
              align="center"
              key={i}
            >
              {day}
            </Typography>
          ))}
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(7, 1fr)",
          }}
          alignContent={"space-between"}
        >
          {data.map((item, index) => {
            return (
              <Box key={index}>
                {item.dateLabel && (
                  <Box sx={{ ...calendarContent, mb: "16px" }}>
                    {type === "Symptoms" && <Symptoms value={item.symptom} />}

                    {type === "Medication" && (
                      <Medication value={item.medication} />
                    )}

                    {type === "Activity" && <Activity value={item.activity} />}

                    {type === "Symptom and Medication" && (
                      <Box sx={{ ...calendarContent, gap: "6px" }}>
                        <Symptoms value={item.symptom} />
                        <SmMedication value={item.medication} />
                      </Box>
                    )}

                    {type === "Symptom and Activity" && (
                      <Box sx={{ ...calendarContent, gap: "6px" }}>
                        <Symptoms value={item.symptom} />
                        <SmActivity value={item.activity} />
                      </Box>
                    )}
                    <Typography
                      fontSize={"19px"}
                      fontWeight="400"
                      color={"#0F1310"}
                    >
                      {item.dateLabel}
                    </Typography>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default Calendar;
