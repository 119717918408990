import { DateTime } from "luxon";

const topSymptoms: any = {
  bradykinesia: "Bradykinesia",
  rigidity: "Rigidity",
  tremor: "Tremor",
  on_off: "On-Off times or Fluctuations",
  off: "Unpredictable 'off'",
  dyskinesia: "Dyskinesia",
  axial_deformities: "Axial Deformities",
  dysphagia: "Dysphagia",
  postural_instability: "Postural Instability",
  falling_down: "Falling Down",
  others: "Others",
};

const qolSymptoms: any = {
  lot: "A lot",
  little: "A Little Bit",
  slightly: "Slightly",
  none: "None",
};

const medAdherence: any = {
  taken: "Taken",
  skipped: "Skipped",
};

const patientReq: any = {
  Jan: "Jan",
  Feb: "Feb",
  Mar: "Mar",
  Apr: "Apr",
  May: "May",
  Jun: "Jun",
  Jul: "Jul",
  Aug: "Aug",
  Sep: "Sep",
  Oct: "Oct",
  Nov: "Nov",
  Dec: "Dec",
};

const colorArray = ["#76B700", "#FACA15", "#F88D22", "#F05252", "#70AE71"];

export const formatAnalyticsData = (
  data: any[],
  hasAllPatientMetricsAccess: Boolean
) => {
  const obj: any = {};
  data.forEach((item) => {
    obj[item.label] = +item.value;
  });
  const ptAnalyticsData = [
    {
      label: "Active/Total patients",
      count: obj["activeParticipants"] || 0,
      total: obj["totalParticipants"] || 0,
    },
    {
      label: "Logged Food/ Total Patients",
      count: obj["food"] || 0,
      total: obj["totalParticipants"] || 0,
    },
    {
      label: "Total Number of Admins",
      count: obj["admins"] || 0,
      hide: !hasAllPatientMetricsAccess,
    },
    {
      label: "Total Number of Clinics",
      count: obj["clinics"] || 0,
      hide: !hasAllPatientMetricsAccess,
    },
    {
      label: "Total Questionnaires",
      count: obj["totalQuestionnaires"] || 0,
    },
    {
      label: "Total Number of Exercise",
      count: obj["exercises"] || 0,
    },
    {
      label: "Total Number of Content",
      count: obj["content"] || 0,
    },
    {
      label: "Total Number of Medication",
      count: obj["medication"] || 0,
    },
  ];
  return ptAnalyticsData;
};

export const formatTroubleSomeSymptoms = (data: any[]) => {
  const obj = data?.[0];
  if (!obj) {
    return [];
  }
  let total = 0;
  Object.values(obj).forEach((value: any) => {
    total += +value;
  });
  let formattedData = Object.entries(obj).map(([key, value]: any) => {
    let percent = 0;
    if (total) {
      percent = (value / total) * 100;
      percent = +percent.toFixed(2);
    }
    return {
      name: topSymptoms[key] || "",
      percent: percent,
      value,
    };
  });
  formattedData.sort((a, b) => b.value - a.value);
  formattedData = formattedData.slice(0, 5).map((item, index) => {
    return {
      ...item,
      color: colorArray[index % 5],
    };
  });
  return formattedData;
};

export const formatQolSymptoms = (data: any[]) => {
  const obj = data?.[0];
  if (!obj) {
    return [];
  }
  let total = 0;
  Object.values(obj).forEach((value: any) => {
    total += +value;
  });
  const formattedData = Object.entries(obj).map(([key, value]: any) => {
    let percent = 0;
    if (total) {
      percent = (value / total) * 100;
      percent = +percent.toFixed(2);
    }
    return {
      label: qolSymptoms[key] || "",
      percent,
    };
  });
  return formattedData;
};

export const formatMedAdherenceSymptoms = (data: any[]) => {
  const obj = data?.[0];
  if (!obj) {
    return [];
  }
  let total = 0;
  Object.values(obj).forEach((value: any) => {
    total += +value;
  });
  const formattedData = Object.entries(obj).map(([key, value]: any) => {
    let percent = 0;
    if (total) {
      percent = (value / total) * 100;
      percent = +percent.toFixed(2);
    }
    return {
      label: medAdherence[key] || "",
      percent,
    };
  });
  return formattedData;
};

export const formatPatientReqSymptoms = (data: any[]) => {
  const monthObj: any = {};

  const endDate = DateTime.now().startOf("month");
  let startDate = DateTime.now().minus({ months: 11 }).startOf("month");

  while (startDate <= endDate) {
    const monthName = startDate.toFormat("LLL");
    monthObj[monthName] = 0;
    startDate = startDate.plus({ months: 1 });
  }

  data.forEach((item) => {
    const date = item?.date?.split("T")?.[0];
    const monthName = DateTime.fromFormat(date, "yyyy-LL-dd").toFormat("LLL");
    monthObj[monthName] = +item.count;
  });

  const formattedData = Object.entries(monthObj).map(([key, value]: any) => {
    return {
      label: patientReq[key] || "",
      value,
    };
  });
  return formattedData;
};
