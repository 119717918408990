import { useEffect, useState } from "react";
import { errorToastMessage } from "../../../../../../utils/toast";
import { DateTime } from "luxon";
import { AxiosResponse } from "axios";
import http from "../../../../../../utils/http";
import { formatQuestionsWithResponsesV2 } from "../../../../../../utils/onboarding";
import { Box, CircularProgress, Typography } from "@mui/material";
import { NoDataContainer } from "../../../../../Common/styles/table";
import QuestionItem from "../QuestionItemV2/QuestionItem";

type Props = {
  date: string;
  logged: boolean;
  id: string | undefined;
  type: string;
};

const DailyLogItem: React.FC<Props> = ({ date, id, logged, type }) => {
  const [data, setData] = useState<any[]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (logged) {
          setLoading(true);
          const start = DateTime.fromFormat(date, "dd LLLL yyyy").startOf(
            "day"
          );
          let end = DateTime.fromFormat(date, "dd LLLL yyyy").endOf("day");
          const res: AxiosResponse = await http.get(
            `/onboarding/${type}/details?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
          );
          const data = res.data.data;
          let questions = formatQuestionsWithResponsesV2(data?.[0].steps, type);
          setData(questions);
          setError("");
          setLoading(false);
        } else {
          setLoading(false);
          setData([]);
        }
      } catch (err) {
        setError("Something went Wrong");
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [date, setLoading, setError, setData, id, logged, type]);

  return loading ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={25} />
    </Box>
  ) : error ? (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        {error}
      </Typography>
    </NoDataContainer>
  ) : data.length > 0 ? (
    <>
      {data.map((question) => {
        return <QuestionItem key={question.id} question={question} />;
      })}
    </>
  ) : (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        No Data
      </Typography>
    </NoDataContainer>
  );
};

export default DailyLogItem;
