import { SxProps } from "@mui/material";

export const rowFlexStyle: SxProps = {
  display: "flex",
  flexDirection: "row",
};

export const rowFlexStyleAlignCenter: SxProps = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

export const colFlexStyle: SxProps = {
  display: "flex",
  flexDirection: "column",
};
export const centerItemFlex: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
export const containerWrapper: SxProps = {
  backgroundColor: "#FFFFFF",
  borderRadius: "8px",
  padding: 2,
  mb: 2,
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
};
