import { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  LinearProgress,
} from "@mui/material";
import Calendar from "./Calendar";
import { useAppSelector } from "../../../../../Redux/hooks";
import { DateTime } from "luxon";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { errorToastMessage } from "../../../../../utils/toast";
import { useParams } from "react-router-dom";
import { NoDataContainer } from "../../../../Common/styles/table";
import { LinearProgressStyle } from "../style";
import {
  troublesomeSymptomsFormatter,
  insightsFormatter,
  transformGraphData,
} from "../../../../../utils/summary";
import Insights from "./Insights";
import MonthlyInsight from "./MonthlyInsight";
import { containerWrapper } from "../../../../Common/styles/flex";

export interface PKGSummaryMetrics {
  dateLabel: string;
  dayLabel: string;
  symptom: string;
  medication: string;
  activity: boolean;
}

const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const PKGSummary = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [calendarData, setCalendarData] = useState<PKGSummaryMetrics[]>([]);
  const [error, setError] = useState("");
  const [top5Symptoms, setTop5Symptoms] = useState<any[]>([]);
  const [insightsData, setInsightsData] = useState<any>({});
  const [monthlyInsightData, setMonthlyInsightData] = useState<any>([]);
  const { startDate, endDate } = useAppSelector((state) => state.health);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (startDate && endDate) {
          setLoading(true);
          const start = DateTime.fromFormat(startDate, "dd-LL-yyyy").startOf(
            "day"
          );
          let end = DateTime.fromFormat(endDate, "dd-LL-yyyy").endOf("day");
          const res: AxiosResponse = await http.get(
            `/activity/pkg/summary?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
          );
          const graphRes: AxiosResponse = await http.get(
            `/reports/summary?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
          );

          const monthlyGraphRes: AxiosResponse = await http.get(
            `/reports/admin/values?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
          );

          const insightsGraphData2: any = insightsFormatter(
            graphRes?.data?.data
          );
          const monthlyGraphData = monthlyGraphRes.data?.data;
          const medData = res.data.data.medication;
          const activityData = res.data.data.exercise;
          const qolData = res.data.data.qualityOfLife;
          const symptomsData = troublesomeSymptomsFormatter(
            res.data.data.troubleSomeSymptoms
          );
          const n = end.diff(start, "days").get("days");

          const graphObj: {
            [key: string]: { monthlyInsights: number };
          } = {};
          const medObj: { [key: string]: string } = {};
          const exerciseObj: { [key: string]: boolean } = {};
          const symptomsObj: { [key: string]: number } = {};

          monthlyGraphData.forEach((day: any) => {
            const date = day?.date;
            if (date) {
              graphObj[date] = {
                monthlyInsights: +day.offPercentage,
              };
            }
          });

          medData.forEach((med: any) => {
            const date = med?.date?.split("T")?.[0];
            if (date) {
              if (med.total === 0) medObj[date] = "none";
              else if (med.total === med.taken) medObj[date] = "completed";
              else if (med.taken > 0) medObj[date] = "incomplete";
              else if (med.skipped > 0) medObj[date] = "skipped";
              else medObj[date] = "none";
            }
          });

          activityData.forEach((act: any) => {
            const date = act?.date?.split("T")?.[0];
            if (date) {
              exerciseObj[date] = +act.time > 0;
            }
          });

          qolData.forEach((symptomData: any) => {
            const date = symptomData.date?.split("T")?.[0];
            if (date) {
              symptomsObj[date] = symptomData.value;
            }
          });
          const newData: any = [];

          for (let i = 0; i < n; i++) {
            const key = end.toFormat("yyyy-LL-dd");
            newData.push({
              dayLabel: end.toFormat("cccc"),
              dateLabel: end.toFormat("d"),
              symptom: symptomsObj[key] || "No Data",
              medication: medObj[key] || "none",
              activity: exerciseObj[key] || false,
              date: end.toFormat("dd/LL/yyyy"),
              monthlyInsights: graphObj[key]
                ? graphObj[key].monthlyInsights
                : 0,
            });
            end = end.plus({
              day: -1,
            });
          }

          const newData1: any = [];
          for (let i = newData.length - 1; i >= 0; i--) {
            newData1.push(newData[i]);
          }

          const firstDayIndex = weekdays.findIndex(
            (day) => day === newData1?.[0]?.dayLabel
          );

          const newData2 = Array.from({ length: firstDayIndex }).map(() => ({
            dayLabel: "",
            dateLabel: "",
            symptom: "",
            medication: "",
            activity: false,
          }));
          const graphData = transformGraphData(newData1);
          newData2.push(...newData1);
          setCalendarData(newData2);
          setTop5Symptoms(symptomsData);
          setInsightsData(insightsGraphData2);
          setMonthlyInsightData(graphData);
          setError("");
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setError("Something went Wrong");
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [startDate, endDate, setLoading, setError, id]);

  return loading ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={25} />
    </Box>
  ) : error ? (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        {error}
      </Typography>
    </NoDataContainer>
  ) : calendarData.length > 0 ? (
    <>
      <Insights insightsData={insightsData} />
      <Box sx={containerWrapper}>
        <Typography fontSize={22} fontWeight={600} color="#355962">
          Off time
        </Typography>
        <MonthlyInsight data={monthlyInsightData} />
      </Box>

      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          padding: "15px 20px",
          mb: 2.5,
          boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Box>
          <Typography
            fontWeight={"medium"}
            fontSize={"22px"}
            color="#355962"
            marginBottom={"8px"}
          >
            Top 5 Symptoms
          </Typography>
          <Box sx={{ mt: 5 }}>
            {top5Symptoms.length > 0 ? (
              top5Symptoms.map((item: any) => (
                <Box
                  sx={{
                    ...LinearProgressStyle,
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: item?.color,
                    },
                  }}
                  key={item.name}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      fontWeight={"regular"}
                      fontSize={"18px"}
                      color="#355962"
                    >
                      {item?.name}
                    </Typography>
                    <Typography
                      fontWeight={"regular"}
                      fontSize={"18px"}
                      color="#355962"
                    >
                      {item?.value}
                    </Typography>
                  </Box>
                  <LinearProgress variant="determinate" value={item?.percent} />
                </Box>
              ))
            ) : (
              <NoDataContainer>
                <Typography variant="body1" color="gray">
                  No Data
                </Typography>
              </NoDataContainer>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(379px, 1fr))",
          gap: 2,
        }}
      >
        <Calendar data={calendarData} type={"Symptoms"} />
        <Calendar data={calendarData} type={"Medication"} />
        <Calendar data={calendarData} type={"Activity"} />
        <Calendar data={calendarData} type={"Symptom and Medication"} />
        <Calendar data={calendarData} type={"Symptom and Activity"} />
      </Box>
    </>
  ) : (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        No Data
      </Typography>
    </NoDataContainer>
  );
};

export default PKGSummary;
