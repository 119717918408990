import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { errorToastMessage } from "../../../../../utils/toast";
import { NoDataContainer } from "../../../../Common/styles/table";
import PhoneInput from "react-phone-input-2";
import { LabelStyle } from "../../../../Common/styles/form";
import AddPatientModal from "../../../../Clinics/Modals/AddPatientModal";
import { DateTime } from "luxon";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import { setToggle } from "../../../../../Redux/reducers/healthSlice";
const mostAffectedSideMap = (side: string) => {
  switch (side) {
    case "1":
      return "Left";
    case "2":
      return "Right";
    case "3":
      return "Both";
    default:
      return "Left";
  }
};

const PatientOverview = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const { id } = useParams();
  const { toggleReload } = useAppSelector((state) => state.health);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(
          `/participants/${id}/profile`
        );
        const response = res.data.data;
        setData({
          id: response?.user?.id,
          email: response?.user?.email,
          phoneNumber: response?.user?.phoneNumber,
          firstName: response?.user?.firstName,
          lastName: response?.user?.lastName,
          name: response?.user?.firstName + " " + response?.user?.lastName,
          verified: response?.user?.isEmailVerified,
          gender: response?.gender,
          dob: response?.dob
            ? DateTime.fromFormat(response?.dob, "yyyy-MM-dd").toFormat(
                "dd/MM/yyyy"
              )
            : "",
          country: response?.user?.country,
          addressLine1: response?.addressLine1,
          addressLine2: response?.addressLine2,
          addressCity: response?.addressCity,
          addressState: response?.addressState,
          addressPostcode: response?.addressPostcode,
          addressCountryISOCode: response?.addressCountryISOCode,
          timezone: response?.user?.timezone,
          mostAffectedSide: response?.mostAffectedSide,
          caregiver: response?.user?.userCaregiver
            ? {
                id: response?.user?.userCaregiver?.careGiver?.id,
                name: response?.user?.userCaregiver?.careGiver?.firstName
                  ? response?.user?.userCaregiver?.careGiver?.firstName +
                    " " +
                    response?.user?.userCaregiver?.careGiver?.lastName
                  : "",
                email: response?.user?.userCaregiver?.careGiver?.email,
                phoneNumber:
                  response?.user?.userCaregiver?.careGiver?.phoneNumber,
                verified:
                  response?.user?.userCaregiver?.careGiver?.isEmailVerified,
              }
            : null,
        });
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [id, setData, setLoading, toggleReload]);

  const refresh = () => {
    dispatch(setToggle());
  };

  const showEditPatientModal = () => {
    setEditModal(true);
  };

  const closeEditModal = () => {
    setEditModal(false);
  };

  return (
    <Box p={4} bgcolor={"#FFFFFF"}>
      {!loading && data?.id && (
        <Box>
          <Box sx={{ display: "grid", gap: 3 }}>
            <Box>
              <Typography sx={LabelStyle}>Name</Typography>
              <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
                {data?.name || "-"}
              </Typography>
            </Box>
            <Box display={"flex"} gap={12}>
              <Box>
                <Typography sx={LabelStyle}>Gender</Typography>
                <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
                  {data?.gender || "-"}
                </Typography>
              </Box>
              <Box>
                <Typography sx={LabelStyle}>Birthday</Typography>
                <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
                  {data?.dob || "-"}
                </Typography>
              </Box>
            </Box>
            <Box display={"flex"} gap={12}>
              <Box>
                <Typography sx={LabelStyle}>Email</Typography>
                <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
                  {data?.email || "-"}
                </Typography>
              </Box>
              <Box>
                <Typography sx={LabelStyle}>Phone Number</Typography>
                <PhoneInput
                  value={data?.phoneNumber || "-"}
                  copyNumbersOnly={false}
                  containerStyle={{
                    width: "100%",
                  }}
                  inputStyle={{
                    border: "none",
                    backgroundColor: "transparent",
                    paddingLeft: 0,
                    width: "fit-content",
                    fontWeight: 400,
                    fontSize: "14px",
                  }}
                  buttonStyle={{
                    display: "none",
                  }}
                  disableDropdown={true}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              <Box>
                <Typography sx={LabelStyle}>Country</Typography>
                <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
                  {data?.addressCountryISOCode || "-"}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography sx={LabelStyle}>Patient Address</Typography>
              {data?.addressLine1 &&
              data?.addressLine2 &&
              data?.addressCity &&
              data?.addressState &&
              data?.addressCountryISOCode &&
              data?.addressPostcode ? (
                <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
                  {data?.addressLine1}, {data?.addressLine2},{" "}
                  {data?.addressCity}, {data?.addressState},{" "}
                  {data?.addressCountryISOCode} - {data?.addressPostcode}
                </Typography>
              ) : (
                <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
                  -
                </Typography>
              )}
            </Box>

            <Box>
              <Typography sx={LabelStyle}>Time Zone</Typography>
              <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
                {data?.timezone || "-"}
              </Typography>
            </Box>
            {data?.mostAffectedSide && (
              <Box>
                <Typography sx={LabelStyle}>Most Affected Side</Typography>
                <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
                  {mostAffectedSideMap(data?.mostAffectedSide) || "-"}
                </Typography>
              </Box>
            )}
          </Box>

          <Box mt={2}>
            <Button onClick={showEditPatientModal} variant="contained">
              Edit
            </Button>
          </Box>
        </Box>
      )}

      {!loading && !data?.id && (
        <NoDataContainer>
          <Typography variant="body2" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}

      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {showEditModal && (
        <AddPatientModal
          showModal={showEditModal}
          closeModal={closeEditModal}
          data={data}
          refreshPage={refresh}
        />
      )}
    </Box>
  );
};

export default PatientOverview;
