import { Box } from "@mui/material";
import QuestionHeader from "./QuestionHeader";
import QuestionList from "./QuestionList";
import { useAppSelector } from "../../Redux/hooks";
import QuestionUrlLoader from "./QuestionUrlLoader";
import QuestionUrlSetter from "./QuestionUrlSetter";
import { canModifyQuestionnaire } from "../../utils/roles";
import { useState } from "react";

const Questions = () => {
  const { urlLoaded } = useAppSelector((state) => state.quesList);
  const userRole = useAppSelector((state)=>state.user.role)
  const [hasModifyAccess] = useState(canModifyQuestionnaire(userRole));
  return urlLoaded ? (
    <>
      <QuestionHeader hasModifyAccess={hasModifyAccess} />
      <Box
        sx={{
          // height: "calc(100% - 85px)",
          // overflow: "auto",
          // padding: 2,
          mt: "24px",
        }}
      >
        <QuestionList hasModifyAccess={hasModifyAccess}/>
      </Box>
      <QuestionUrlSetter />
    </>
  ) : (
    <QuestionUrlLoader />
  );
};

export default Questions;
