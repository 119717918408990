import React, { useMemo, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import http from "../../../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../../../utils/toast";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../../../../Common/styles/modal";
import { InputWrapper } from "../../../../../ExerciseCollection/ExerciseCollection.style";
import { LabelStyle } from "../../../../../Common/styles/form";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { debounce } from "lodash";
import { AxiosResponse } from "axios";
import { Add, Delete } from "@mui/icons-material";
import { DateTime } from "luxon";

const FrequencyItems = [
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "On Specific Days of the Week",
    value: "day",
  },
  {
    label: "As-needed",
    value: "as_needed",
  },
];

const DaysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const schema = yup.object().shape({
  name: yup.string().required("*Medication Name is Required"),
  id: yup.string().required(),
  dose: yup.string().optional(),
  frequency: yup.string().required("*Frequency is Required"),
  startDate: yup.string().required("*Start Date is Required"),
  endDate: yup.string().optional(),
  notes: yup.string().optional(),
  dayTimings: yup.array().when("frequency", {
    is: (frequency: string) => frequency === "day" || frequency === "daily",
    then: (schema) =>
      schema.of(
        yup.object().shape({
          timing: yup.string().required("*Time is Required"),
          isReminderEnabled: yup.boolean().optional(),
        })
      ),
    otherwise: (schema) =>
      schema.of(
        yup.object().shape({
          timing: yup.string().optional().nullable(),
          isReminderEnabled: yup.boolean().optional(),
        })
      ),
  }),
});

const AddPrescriptionModal: React.FC<any> = ({
  showModal,
  closeModal,
  refreshPage,
  userId,
}: any) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [medications, setMedications] = useState<any>([]);
  const [strengths, setStrengths] = useState<any[]>([]);

  const submitHandler = async (values: any) => {
    try {
      const startDate = DateTime.fromFormat(values.startDate, "dd/MM/yyyy")
        .startOf("day")
        .toUTC()
        .toISO();
      const endDate = values.endDate
        ? DateTime.fromFormat(values.endDate, "dd/MM/yyyy")
            .endOf("day")
            .toUTC()
            .toISO()
        : null;

      const modiFiedDayTimings = values?.dayTimings?.map((dayTime: any) => {
        // const time = DateTime.fromISO(dayTime.timing).toFormat("HH:mm");
        return {
          timing: dayTime.timing,
          isReminderEnabled: dayTime.isReminderEnabled,
        };
      });
      const body: any = {
        name: values.name,
        medicationId: values.id,
        strength: values.dose,
        notes: values.notes,
        userId: userId,
        reminder: {
          reminderWindowLength: "15",
          frequency: values.frequency,
          daysEnabled: values.daysEnabled,
          dayTimings: modiFiedDayTimings,
          startDate: startDate,
          endDate: endDate,
        },
      };
      if (body.reminder.frequency === "daily") {
        body.reminder.daysEnabled = [1, 1, 1, 1, 1, 1, 1];
      }
      if (
        body.reminder.frequency === "as_needed" ||
        body.reminder.frequency === "none"
      ) {
        body.reminder.daysEnabled = [0, 0, 0, 0, 0, 0, 0];
        body.reminder.dayTimings = [];
      }
      setButtonLoader(true);
      let res: AxiosResponse = await http.post(`/medication/prescribe`, body);
      toastMessage("success", res.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          if (value) {
            setSearchLoader(true);
            let url = `/medication-management?search=${value}&noPagination=true`;
            const res: AxiosResponse = await http.get(url);
            const meds = res.data.data.medications.map((med: any) => {
              return {
                name: med?.propreiteryName,
                id: med?.id,
                strength: med?.strength,
              };
            });
            setMedications(meds);
            setSearchLoader(false);
          }
        } catch (err) {
          errorToastMessage(err as Error);
          setSearchLoader(false);
        }
      }, 500),
    []
  );
  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={"Add Prescribe Medication"}
          onCloseClick={closeModal}
        />
        <Formik
          initialValues={{
            id: "",
            name: "",
            dose: "",
            notes: "",
            frequency: "daily",
            daysEnabled: [0, 0, 0, 0, 0, 0, 0],
            dayTimings: [{ timing: "", isReminderEnabled: false }],
            startDate: null,
            endDate: "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ errors, touched, getFieldProps, values, setFieldValue }: any) => (
            <Form>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="medicationName">
                  Medication Name
                </FormLabel>
                <Autocomplete
                  // freeSolo
                  id="medicationName"
                  filterOptions={(x) => x}
                  onInputChange={(_1: any, value: any, reason: string) => {
                    if (reason === "input") {
                      handleSearch(value);
                    }
                    // setFieldValue("medication", {
                    //   name: value,
                    //   id: null,
                    // });
                  }}
                  onChange={(_1: any, value: any) => {
                    if (value?.id) {
                      setFieldValue("id", value.id);
                      setFieldValue("name", value.name);
                      const selectedMedication = medications.find(
                        (med: any) => med.id === value.id
                      );
                      if (selectedMedication) {
                        setStrengths(selectedMedication.strength);
                      } else {
                        setStrengths([]);
                      }
                    } else {
                      setFieldValue("id", "");
                      setFieldValue("name", "");
                      setStrengths([]);
                    }
                  }}
                  options={medications}
                  value={values?.id || null}
                  getOptionLabel={() => {
                    return values?.name || "";
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value;
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  loading={searchLoader}
                  loadingText={<CircularProgress size={20} />}
                  noOptionsText="No Results"
                  clearOnBlur={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search medications by typing..."
                    />
                  )}
                />
                <FormHelperText error>
                  {touched?.name && errors?.name
                    ? (errors?.name as string)
                    : " "}
                </FormHelperText>
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="dose">
                  Available Doses
                </FormLabel>
                <Autocomplete
                  id="dose"
                  freeSolo
                  filterOptions={(x) => x}
                  onInputChange={(_1: any, value: any) => {
                    setFieldValue("dose", value);
                  }}
                  onChange={(_1: any, value: any) => {
                    setFieldValue("dose", value);
                  }}
                  options={strengths}
                  value={values?.dose}
                  getOptionLabel={(option) => option}
                  clearOnBlur={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Available Doses"
                      {...getFieldProps("dose")}
                    />
                  )}
                />
                <FormHelperText error>
                  {touched?.dose &&
                  errors?.dose &&
                  typeof errors?.dose === "string"
                    ? "*Available Doses is Required"
                    : " "}
                </FormHelperText>
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="frequency">
                  Frequency
                </FormLabel>
                <Select id="frequency" {...getFieldProps("frequency")}>
                  {FrequencyItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {values.frequency === "day" && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <FieldArray name="daysEnabled">
                    {({ replace }) => (
                      <>
                        {values?.daysEnabled.map(
                          (val: number, index: number) => (
                            <Box
                              key={index}
                              sx={{
                                bgcolor: val === 1 ? "#327091" : "#F3F4F6",
                                color: val === 1 ? "#fff" : "#111928",
                                borderRadius: "8px",
                                p: "15px 0",
                                display: "flex",
                                justifyContent: "center",
                                minWidth: "50px",
                                cursor: "pointer",
                              }}
                              onClick={() => replace(index, val === 1 ? 0 : 1)}
                            >
                              <Typography variant="subtitle1">
                                {DaysOfWeek[index]}
                              </Typography>
                            </Box>
                          )
                        )}
                      </>
                    )}
                  </FieldArray>
                </Box>
              )}
              <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="startDate">
                    Start Date
                  </FormLabel>
                  <DatePicker
                    // minDate={maxStart}
                    disablePast
                    inputFormat="dd/MM/yyyy"
                    value={
                      values?.startDate
                        ? DateTime.fromFormat(values?.startDate, "dd/MM/yyyy")
                        : null
                    }
                    onChange={(newValue: any) => {
                      setFieldValue(
                        "startDate",
                        newValue.toFormat("dd/MM/yyyy")
                      );
                      if (
                        values.endDate &&
                        newValue >
                          DateTime.fromFormat(values?.endDate, "dd/MM/yyyy")
                      ) {
                        setFieldValue("endDate", "");
                      }
                    }}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps
                    ) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                          placeholder: "Select date",
                          id: "startDate",
                        }}
                        error={
                          touched?.startDate && errors?.startDate ? true : false
                        }
                        helperText={
                          touched?.startDate && errors?.startDate
                            ? (errors?.startDate as string)
                            : " "
                        }
                      />
                    )}
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="endDate">
                    End Date
                  </FormLabel>
                  <DatePicker
                    disablePast
                    minDate={
                      values?.startDate
                        ? DateTime.fromFormat(values?.startDate, "dd/MM/yyyy")
                        : DateTime.now().startOf("day")
                    }
                    inputFormat="dd/MM/yyyy"
                    value={
                      values?.endDate
                        ? DateTime.fromFormat(values?.endDate, "dd/MM/yyyy")
                        : null
                    }
                    onChange={(newValue: any) => {
                      setFieldValue("endDate", newValue.toFormat("dd/MM/yyyy"));
                    }}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps
                    ) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                          placeholder: "Select date",
                          id: "endDate",
                        }}
                        error={
                          touched?.endDate && errors?.endDate ? true : false
                        }
                        helperText={
                          touched?.endDate && errors?.endDate
                            ? (errors?.endDate as string)
                            : " "
                        }
                      />
                    )}
                  />
                </FormControl>
              </Box>
              {(values.frequency === "day" || values.frequency === "daily") && (
                <FieldArray name="dayTimings">
                  {({ remove, push }) => (
                    <>
                      {values?.dayTimings?.map(
                        (dayTime: any, index: number) => (
                          <Box sx={{ display: "flex", gap: 2 }} key={index}>
                            <FormControl sx={InputWrapper}>
                              <FormLabel sx={LabelStyle} htmlFor="timing">
                                Time
                              </FormLabel>
                              <TimePicker
                                inputFormat="hh:mm a"
                                value={
                                  dayTime.timing
                                    ? DateTime.fromFormat(
                                        dayTime.timing,
                                        "HH:mm"
                                      )
                                    : null
                                }
                                onChange={(newValue: any) => {
                                  setFieldValue(
                                    `dayTimings.${index}.timing`,
                                    newValue.toFormat("HH:mm")
                                  );
                                }}
                                // minutesStep={30}
                                renderInput={(
                                  params: JSX.IntrinsicAttributes &
                                    TextFieldProps
                                ) => (
                                  <TextField
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                      readOnly: true,
                                      placeholder: "Select time",
                                      id: "timing",
                                    }}
                                    error={
                                      touched?.dayTimings?.[index]?.timing &&
                                      errors?.dayTimings?.[index]?.timing
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      touched?.dayTimings?.[index]?.timing &&
                                      errors?.dayTimings?.[index]?.timing
                                        ? errors?.dayTimings?.[index]?.timing
                                        : " "
                                    }
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl sx={InputWrapper}>
                              <FormLabel sx={LabelStyle} htmlFor="notify">
                                Notify
                              </FormLabel>

                              <Switch
                                checked={dayTime.isReminderEnabled}
                                onChange={(e) =>
                                  setFieldValue(
                                    `dayTimings.${index}.isReminderEnabled`,
                                    e.target.checked
                                  )
                                }
                              />
                            </FormControl>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <IconButton
                                onClick={() =>
                                  push({
                                    timing: "",
                                    isReminderEnabled: false,
                                  })
                                }
                              >
                                <Add />
                              </IconButton>
                              <IconButton
                                disabled={values?.dayTimings.length === 1}
                                color="error"
                                onClick={() => remove(index)}
                              >
                                <Delete />
                              </IconButton>
                            </Box>
                          </Box>
                        )
                      )}
                    </>
                  )}
                </FieldArray>
              )}

              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="notes">
                  Notes
                </FormLabel>
                <TextField
                  multiline
                  minRows={2}
                  id="notes"
                  placeholder="Notes"
                  error={touched?.notes && errors?.notes ? true : false}
                  helperText={
                    touched?.notes && errors?.notes ? errors?.notes : " "
                  }
                  {...getFieldProps("notes")}
                />
              </FormControl>

              <Box sx={ModalActionButtonStyles}>
                {!buttonLoader ? (
                  <>
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                    <Button variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddPrescriptionModal;
