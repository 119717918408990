import { useAppSelector } from "../../../Redux/hooks";
import DailyLog from "./Block/Questionnaire/DailyLog/DailyLog";
import Exercises from "./Block/Exercises/Exercises";
import Food from "./Block/Food/Food";
import Medication from "./Block/Medication/Medication";
import Onboarding from "./Block/Questionnaire/Onboarding/Onboarding";
import Questionnaire from "./Block/Questionnaire/QuestionBlock/Questionnaire";
import PKGSummary from "./Block/PKGSummary/PKGSummary";
import PatientOverview from "./Block/PatientOverview/PatientOverview";
import Reports from "./Block/Reports/Reports";

const HealthBlockSelector = () => {
  const { type } = useAppSelector((state) => state.health);

  switch (type) {
    case "patient_overview":
      return <PatientOverview />;
    case "pkg_summary":
      return <PKGSummary />;
    case "food":
      return <Food />;
    case "onboarding":
      return <Onboarding />;
    case "medication":
      return <Medication />;
    case "exercise":
      return <Exercises />;
    case "daily_log":
      return <DailyLog type="daily-log" />;
    case "symptoms":
      return <DailyLog type="daily-symptoms" />;
    case "questionnaire":
      return <Questionnaire />;
    case "reports":
      return <Reports />;
    default:
      return null;
  }
};

export default HealthBlockSelector;
