import { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

import { errorToastMessage } from "../../../../../../utils/toast";

import QuestionMenu from "./QuestionMenu";
import { NoDataContainer } from "../../../../../Common/styles/table";
import { sleepFn } from "../../../../../../utils/delay";
// import { AxiosResponse } from "axios";
import { useAppSelector } from "../../../../../../Redux/hooks";
import { useParams } from "react-router-dom";
import QuestionResponse from "./QuestionResponse";
import { DateTime } from "luxon";
import http from "../../../../../../utils/http";
import {
  questionResponseFormat,
  questionResponseFormatV2,
} from "../../../../../../utils/question";
import { AxiosResponse } from "axios";

const Questionnaire = () => {
  const [loading, setLoading] = useState(false);
  const [responseLoader, setResponseLoader] = useState(false);
  const [error, setError] = useState("");
  const [responseError, setResponseError] = useState("");
  const { startDate, endDate } = useAppSelector((state) => state.health);
  const { id } = useParams();

  const [questionnaireList, setQuestionnaireList] = useState<any>([]);
  const [responseData, setResponseData] = useState<any>(null);

  useEffect(() => {
    const fetchQuestionnaireList = async () => {
      try {
        if (startDate && endDate) {
          setLoading(true);
          await sleepFn(500);
          const start = DateTime.fromFormat(startDate, "dd-LL-yyyy").startOf(
            "day"
          );
          let end = DateTime.fromFormat(endDate, "dd-LL-yyyy").endOf("day");
          const res: AxiosResponse = await http.get(
            `/questionnaires/get_completed_attempts?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
          );
          const data = res.data.data;
          setQuestionnaireList(data);
          setResponseData(null);
          setLoading(false);
          setError("");
        } else {
          setLoading(false);
        }
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
        setError("Something went Wrong");
      }
    };

    fetchQuestionnaireList();
  }, [startDate, endDate, setLoading, setError, id]);

  const handleResponseClick = async (
    questionId: string,
    attemptId: string,
    date: string
  ) => {
    try {
      setResponseLoader(true);
      let { data } = await http.get(`/questionnaires/${questionId}/questions`);
      let questions = questionResponseFormat(
        data.data.questions,
        data.data.logicJumps
      );

      const responseData = await http.get(
        `questionnaires/${attemptId}/get_attempt_responses/${id}`
      );
      const responseList = responseData.data.data;
      questionResponseFormatV2(questions, responseList);
      setResponseData({
        name: data.data.name,
        questions,
        date,
      });
      setResponseLoader(false);
      setResponseError("");
    } catch (err) {
      errorToastMessage(err as Error);
      setResponseLoader(false);
      setResponseError("Something went Wrong");
    }
  };

  return loading ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={25} />
    </Box>
  ) : error ? (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        {error}
      </Typography>
    </NoDataContainer>
  ) : (
    <Box sx={{ display: "flex", gap: 2, alignItems: "flex-start" }}>
      <QuestionMenu
        data={questionnaireList}
        handleResponseClick={handleResponseClick}
      />
      <QuestionResponse
        loading={responseLoader}
        error={responseError}
        data={responseData}
      />
    </Box>
  );
};

export default Questionnaire;
