import { Box, InputAdornment, TextField } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { setCmsSearchText, setCmsType } from "../../Redux/reducers/cmsSlice";
import {
  a11yProps,
  MainTabPanel,
  StyledTab,
  StyledTabs,
} from "../Common/UI/TabPanel";
import CMSHeader from "./CMSHeader";
import CMSUrlLoader from "./CMSUrlLoader";
import CMSUrlSetter from "./CMSUrlSetter";
import EducationModules from "./Modules/EducationModule.tsx/EducationModules";
import LearningModules from "./Modules/LearningModule/LearningModules";
import { canModifyCMS } from "../../utils/roles";
import { useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";

const CMS = () => {
  const dispatch = useAppDispatch();
  const { type, urlLoaded, searchText } = useAppSelector((state) => state.cms);
  const userRole = useAppSelector((state) => state.user.role);
  const handleChange = (_: any, newValue: string) => {
    dispatch(setCmsType(newValue));
  };
  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        dispatch(setCmsSearchText(val));
      }, 500),
    [dispatch]
  );

  const [hasModifyAccess] = useState(canModifyCMS(userRole));

  return urlLoaded ? (
    <>
      <CMSHeader hasModifyAccess={hasModifyAccess} />
      <Box sx={{ borderTop: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab
            label="Learning Modules"
            value="learning"
            {...a11yProps(0)}
          />
          <StyledTab
            label="Education Modules"
            value="education"
            {...a11yProps(1)}
          />
        </StyledTabs>
      </Box>
      <Box sx={{ mt: "24px" }}>
        <Box
          sx={{
            padding: "16px",
            bgcolor: "#FFFFFF",
            borderRadius: type === "learning" ? "8px" : "8px 8px 0 0",
          }}
        >
          <TextField
            onChange={(e) => modifySearchTerm(e.target.value)}
            style={{ width: "70%" }}
            placeholder="Search for Content"
            defaultValue={searchText}
            key={type}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <MainTabPanel value={type} index={"learning"}>
          <LearningModules hasModifyAccess={hasModifyAccess} />
        </MainTabPanel>
        <MainTabPanel value={type} index={"education"}>
          <EducationModules hasModifyAccess={hasModifyAccess} />
        </MainTabPanel>
      </Box>
      <CMSUrlSetter />
    </>
  ) : (
    <CMSUrlLoader />
  );
};

export default CMS;
