import { useState } from "react";
import { Box, Modal, Typography, IconButton, Divider } from "@mui/material";
import {
  MainTabPanel,
  StyledTab,
  StyledTabs,
  a11yProps,
} from "../../../Common/UI/TabPanel";
import CaregiverDetails from "./Tabs/CaregiverDetails";
import { ModalBaseStyles } from "../../../Common/styles/modal";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CaregiverPast from "./Tabs/CaregiverPast";

const CaregiverTabs: React.FC<any> = ({
  setDetailModal,
  caregiver,
  showAddCaregiverModal,
}) => {
  const [type, setType] = useState("caregiverCurrent");

  const closeModal = () => {
    setDetailModal(false);
  };

  const handleChange = (_: any, newValue: string) => {
    setType(newValue);
  };

  return (
    <Modal open={true} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        {/* <ModalHeader title={"Caregiver Details"} onCloseClick={closeModal} /> */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "#355962",
          }}
        >
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            textTransform={"capitalize"}
          >
            Caregiver Details
          </Typography>
          <IconButton onClick={closeModal}>
            <CloseRoundedIcon htmlColor="#637E85" />
          </IconButton>
        </Box>
        <Divider sx={{ mt: 1 }} />
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "#E5E7EB" }}>
            <StyledTabs value={type} onChange={handleChange}>
              <StyledTab
                label="Current"
                value="caregiverCurrent"
                {...a11yProps(0)}
              />
              <StyledTab label="Past" value="caregiverPast" {...a11yProps(1)} />
            </StyledTabs>
          </Box>
          <Box sx={{ mt: "36px" }}>
            <MainTabPanel value={type} index={"caregiverCurrent"}>
              <CaregiverDetails
                caregiver={caregiver}
                setDetailModal={setDetailModal}
                showAddCaregiverModal={showAddCaregiverModal}
              />
            </MainTabPanel>
            <MainTabPanel value={type} index={"caregiverPast"}>
              <CaregiverPast />
            </MainTabPanel>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CaregiverTabs;
