import { Box, Typography } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
const colorByValue = (value: any) => {
  if (value <= 20) return "#F05252";
  else if (value <= 40) return "#F88D22";
  else if (value <= 60) return "#FACA15";
  else if (value <= 80) return "#76B700";
  else return "#70AE71";
};
const PatientRequestsBar = ({ data }: any) => {
  return (
    <Box
      sx={{
        flex: 1,
        minWidth: "580px",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        padding: "15px 20px",
        mb: 2.5,
        boxShadow: " 0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
      }}
    >
      <Box>
        <Typography
          fontWeight={"medium"}
          fontSize={"22px"}
          color="#355962"
          marginBottom={"8px"}
        >
          {data?.title || "Patient Requests"}
        </Typography>
      </Box>
      <Box sx={{ height: "50vh", maxHeight: "400px" }}>
        <ResponsiveBar
          data={(data?.data || [])?.map((item: any) => ({
            label: item?.label,
            requests: item?.value,
          }))}
          keys={["requests"]}
          indexBy="label"
          colors={(d: any) => colorByValue(d.formattedValue)}
          margin={{ top: 40, right: 10, bottom: 50 }}
          padding={0.55}
          valueScale={{ type: "linear", min: 0, max: "auto", clamp: true }}
          indexScale={{ type: "band" }}
          // gridYValues={[20, 40, 60, 80, 100]}
          theme={{
            fontSize: 14,
            grid: {
              line: {
                strokeDasharray: "6 6",
              },
            },
            axis: {
              domain: {
                line: {
                  stroke: "#E0E3EB",
                },
              },
              ticks: {
                text: {
                  fill: "#355962",
                  fontSize: 14,
                  fontWeight: 550,
                },
              },
              legend: {
                text: {
                  fill: "#9CA3AF",
                  fontSize: 14,
                  fontWeight: 500,
                },
              },
            },
          }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 12,
            tickRotation: 0,
            legendPosition: "middle",
            legendOffset: 40,
          }}
          axisLeft={null}
          enableLabel={false}
          ariaLabel="Patient Requests"
        />
      </Box>
    </Box>
  );
};

export default PatientRequestsBar;
