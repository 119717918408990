import { SxProps } from "@mui/material";

export const linearProgressText: SxProps = {
  position: "relative",
  bottom: "10px",
  color: "#111928",
  fontSize: "16px",
  fontWeight: 400,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};
export const linearProgressLabel: SxProps = {
  color: "#637E85",
  fontSize: "16px",
  fontWeight: 500,
  position: "relative",
  bottom: "27px",
};
