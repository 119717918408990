import { Box, Typography } from "@mui/material";
import React from "react";
import {
  FlexContainer,
  GrayBox,
  HeaderStyle,
  ListStyle,
  PolicyBorderedContainer,
  PolicyContainer,
  titleText,
} from "./settings.style";

const DeleteAccountSteps = () => {
  return (
    <>
      <Box sx={HeaderStyle}>
        <img src="/header-logo.svg" alt="logo" />
      </Box>
      <Box sx={GrayBox} />
      <Box
        sx={{ height: "calc(100% - 143px)", overflow: "auto", bgcolor: "#fff" }}
      >
        <Box sx={PolicyContainer}>
          <Box sx={PolicyBorderedContainer}>
            <Typography
              fontWeight={"medium"}
              sx={titleText}
              color={"#111928"}
              mb={"100px"}
            >
              Steps for Deleting your Account
            </Typography>
            <Box
              sx={{
                ...FlexContainer,
                mr: "10%",
                mb: "80px",
              }}
            >
              <img
                src="/step-1.png"
                alt="step-1"
                style={{
                  width: "323px",
                  height: "609px",
                  marginRight: "20%",
                  marginBottom: "10px",
                  maxWidth: "100%",
                }}
              />
              <Box>
                <Typography
                  fontSize={37}
                  fontWeight={"regular"}
                  color="#111928"
                  mb={"50px"}
                >
                  Step 1
                </Typography>
                <Typography
                  fontSize={26}
                  fontWeight={"light"}
                  color={"#111928"}
                  sx={ListStyle}
                >
                  From the home screen Tap on{" "}
                  <Typography
                    component="span"
                    fontSize={26}
                    fontWeight={"medium"}
                    color={"#5091CD"}
                  >
                    Profile
                  </Typography>{" "}
                  Icon
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                ...FlexContainer,
                mb: "80px",
              }}
            >
              <Box>
                <Typography
                  fontSize={37}
                  fontWeight={"regular"}
                  color="#111928"
                  mb={"50px"}
                >
                  Step 2
                </Typography>
                <Typography
                  fontSize={26}
                  fontWeight={"light"}
                  color={"#111928"}
                  sx={ListStyle}
                >
                  Tap on the{" "}
                  <Typography
                    component="span"
                    fontSize={26}
                    fontWeight={"medium"}
                    color={"#5091CD"}
                  >
                    Preferences{" "}
                  </Typography>
                  Option
                </Typography>
              </Box>
              <img
                src="/step-2.png"
                alt="step-2"
                style={{
                  width: "340px",
                  height: "626px",
                  marginLeft: "auto",
                  marginTop: "10px",
                  maxWidth: "100%",
                }}
              />
            </Box>
            <Box
              sx={{
                ...FlexContainer,
                mb: "80px",
              }}
            >
              <img
                src="/step-3.png"
                alt="step-3"
                style={{
                  width: "282px",
                  height: "611px",
                  marginRight: "20%",
                  marginBottom: "10px",
                  maxWidth: "100%",
                }}
              />
              <Box>
                <Typography
                  fontSize={37}
                  fontWeight={"regular"}
                  color="#111928"
                  mb={"50px"}
                >
                  Step 3
                </Typography>
                <Typography
                  fontSize={26}
                  fontWeight={"light"}
                  color={"#111928"}
                  sx={ListStyle}
                >
                  Tap on the{" "}
                  <Typography
                    component="span"
                    fontSize={26}
                    fontWeight={"medium"}
                    color={"#D80000"}
                  >
                    Delete Account{" "}
                  </Typography>
                  Option
                </Typography>
              </Box>
            </Box>
            <Box sx={FlexContainer}>
              <Box>
                <Typography
                  fontSize={37}
                  fontWeight={"regular"}
                  color="#111928"
                  mb={"50px"}
                >
                  Step 4
                </Typography>
                <Typography
                  fontSize={26}
                  fontWeight={"light"}
                  color={"#111928"}
                  sx={ListStyle}
                >
                  On confirming{" "}
                  <Typography
                    component="span"
                    fontSize={26}
                    fontWeight={"medium"}
                    color={"#D80000"}
                  >
                    Delete{" "}
                  </Typography>
                  , All data related to this account such as <b>Username</b> ,
                  <b> Password </b>
                  and <b>Phone Number</b> will be deleted.
                </Typography>
              </Box>
              <img
                src="/step-4.png"
                alt="step-4"
                style={{
                  width: "282px",
                  height: "611px",
                  marginLeft: "auto",
                  marginBottom: "10px",
                  maxWidth: "100%",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DeleteAccountSteps;
