import { Box, Divider, SxProps, Typography } from "@mui/material";
import { foodEntry } from "./Food";
import { PhotoSizeSelectActualOutlined } from "@mui/icons-material";

const foodWrapper: SxProps = {
  border: 1,
  borderColor: "divider",
  p: 2,
  borderRadius: "12px",
  minHeight: "320px",
  width: "272px",
  ".foodImage": {
    width: "100%",
    height: "130px",
    borderRadius: "12px 12px 0px 0px",
    objectFit: "cover",
  },
};

const FoodItem: React.FC<{ food: foodEntry }> = ({ food }) => {
  return (
    <Box sx={foodWrapper}>
      <Typography variant="h6" fontWeight={"medium"} color="#355962">
        {food.name}
      </Typography>
      <Divider sx={{ my: 2 }} />
      {food.imageUrl ? (
        <img src={food.imageUrl} className="foodImage" alt={food.name} />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "130px",
            background: "#e0e3eb",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "12px 12px 0px 0px",
          }}
        >
          <PhotoSizeSelectActualOutlined
            sx={{ width: "45px", height: "45px", color: "#355962" }}
          />
        </Box>
      )}
      <Box sx={{ display: "flex", mt: 1, mb: 2 }}>
        <Typography variant="body1" color="#637E85" mr={2}>
          {food.time}
        </Typography>
        <Typography
          variant="body1"
          color="#637E85"
          ml={1}
          sx={{ display: "list-item" }}
          textTransform={"capitalize"}
        >
          {food.serving}
        </Typography>
      </Box>
      <Typography
        variant="body1"
        color="#637E85"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {food.notes}
      </Typography>
    </Box>
  );
};

export default FoodItem;
